export const POST_HOG_EVENT = {
  SIGN_UP_DIRECT: 'sign-up-direct',
  SIGN_UP_VIA_HABIT_PACK: 'sign-up-via-habit-pack',
  SIGN_UP_VIA_FOCUS_TEMPLATE: 'sign-up-via-focus-template',
  TODO_PLAYER_ADD_TASK_BUTTON_CLICKED: 'todo-player-add-task-button-clicked',
  TODO_PLAYER_EXISTING_TASK_ADDED_FROM_ADD_TASK_UI:
    'todo-player-existing-task-added-from-add-task-ui',
  TODO_PLAYER_BRAINDUMP_AI_BUTTON_CLICKED:
    'todo-player-braindump-ai-button-clicked',
  TODO_PLAYER_TASK_PLAY_BUTTON_CLICKED: 'todo-player-task-play-button-clicked',
  FOCUS_SUMMARY: 'focus_summary',
  ROUTINE_SUGGESTIONS_GOALS_SELECTION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-goals-selection-continue-button-clicked',
  ROUTINE_SUGGESTIONS_ROUTINE_SELECTION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-routine-selection-continue-button-clicked',
  ROUTINE_SUGGESTIONS_ROUTINE_DURATION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-routine-duration-continue-button-clicked',
  ROUTINE_SUGGESTIONS_SUGGEST_HABITS_FOR_ME_BUTTON_CLICKED:
    'routine-suggestions-suggest_habits_for_me_button-clicked',
  ROUTINE_SUGGESTIONS_EDIT_HABITS_BUTTON_CLICKED:
    'routine-suggestions-edit-habits-button-clicked',
  ROUTINE_SUGGESTIONS_USE_GENERATED_BUTTON_CLICKED:
    'routine-suggestions-use-generated-button-clicked',
  ROUTINE_SUGGESTIONS_EXPLORE_HABIT_PACKS_BUTTON_CLICKED:
    'routine-suggestions-explore-habit-packs-button-clicked',
  ROUTINE_SUGGESTIONS_INSTALL_HABIT_PACK_BUTTON_CLICKED:
    'routine-suggestions-install-habit-pack-button-clicked',
  PERSONAL_SUBSCRIPTION_GET_STARTED_BUTTON_CLICKED:
    'personal-subscription-get-started-button-clicked',
  TEAM_SUBSCRIPTION_GET_STARTED_BUTTON_CLICKED:
    'team-subscription-get-started-button-clicked',
  RENEW_SUBSCRIPTION_BUTTON_CLICKED: 'renew-subscription-button-clicked',
  CHANGE_PAYMENT_METHOD_BUTTON_CLICKED: 'change-payment-method-button-clicked',
  ADD_PAYMENT_METHOD_BUTTON_CLICKED: 'add-payment-method-button-clicked',
  CANCEL_SUBSCRIPTION_BUTTON_CLICKED: 'cancel-subscription-button-clicked',
  CANCEL_SUBSCRIPTION_CONTINUE_BUTTON_CLICKED:
    'cancel-subscription-continue-button-clicked',
  TUTORIAL_COURSE_ENROLL_BUTTON_CLICKED:
    'tutorial-course-enroll-button-clicked',
  ROUTINE_SUGGESTIONS_NO_SUGGESTIONS_GENERATED:
    ' routine-suggestions-no-suggestions-generated',
  ROUTINE_SUGGESTIONS_HABIT_TITLE_EDIT_HABIT_BUTTON_CLICKED:
    'routine-suggestions-habit-title-edit-habit-button-clicked'
};

export const MOBILE_APP_EVENT = {
  SUGGESTED_ACTIVITIES_INSTALLED: 'suggested-activities-installed',
  SEND_SELECTED_HABIT_PACK_ID: 'send-selected-habit-pack-id',
  EDIT_HABITS_ENABLED: 'edit-habits-enabled',
  SAVED_USER_SETTINGS: 'saved-user-settings'
};

export const AI_EVENT = {
  FATAL_ERROR: 'FatalError'
};

export const PLATFORMS_MESSAGE_HANDLER = {
  USER_SETTINGS_UPDATED: 'user-settings-updated',
  USER_SETTINGS_UPDATE_FAILED: 'user-settings-update-failed'
};
