import classNames from 'classnames';
import DropDown from 'components/common/DropDown';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import {
  CUSTOM_ROUTINE_FEATURE,
  REACT_SELECT_DROPDOWN
} from 'constants/general';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import { useContext, useMemo } from 'react';
import { MultiValue } from 'react-select';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import {
  daysOfWeekOptions,
  updateDaysOfWeek,
  updateSelectedDaysOfWeek
} from 'utils/settingsUtil';
import { CustomRoutineContext } from '..';

const RoutineDaysOfWeek = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);

  const [isScheduledModeEnabled, selectedDays] = useMemo(
    () => [
      customRoutine?.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE,
      updateSelectedDaysOfWeek(customRoutine?.days_of_week ?? [])
    ],
    [customRoutine]
  );

  return (
    <DropDown
      value={selectedDays}
      isMulti
      handleChange={(data) => {
        const result = data as MultiValue<DropDownSelectedValue>;
        customRoutine &&
          dispatch(
            updateCustomRoutine({
              feature: CUSTOM_ROUTINE_FEATURE.DAYS,
              position: customRoutine?.position,
              value: updateDaysOfWeek(result)
            })
          );
      }}
      options={daysOfWeekOptions}
      title={t('which_days_of_the_week_this_activity_should_be_used')}
      containerStyles={classNames('w-fit flex-col gap-1 animate-fadeIn', {
        'inline-flex': isScheduledModeEnabled,
        hidden: !isScheduledModeEnabled
      })}
      labelStyles='text-xs'
      zIndex={REACT_SELECT_DROPDOWN.Z_INDEX.CUSTOM_ROUTINE}
      closeMenuOnSelect={false}
    />
  );
};

export default RoutineDaysOfWeek;
