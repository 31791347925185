import { PropsWithChildren, useEffect } from 'react';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateMoreOptionIsUseWebsiteChecked,
  updateMoreOptionShowAllowedURLModal
} from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import { SWITCH } from 'constants/tag';
import { increment } from 'utils/support';
import { moreOptionsWebsitesSelector } from 'store/reducer/setting/selectors';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-[97%] md:w-[40%] flex flex-col'>{children}</div>
);

const Websites = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity, isUseWebsiteChecked } = useAppSelector(
    moreOptionsWebsitesSelector
  );

  useEffect(() => {
    dispatch(
      updateMoreOptionIsUseWebsiteChecked(
        Boolean(activity.allowed_urls?.length)
      )
    );
  }, []);

  return (
    <Wrapper>
      <div className='w-full flex gap-2.5 py-1'>
        <label
          data-test={ATTRIB.TEST.SWITCH_USE_WEBSITES}
          className='w-fit sm:w-[55%] lg:w-[55%] flex gap-2 text-xs lg:text-sm font-medium  text-left line-clamp-2 cursor-pointer'
        >
          <Switch
            width={SWITCH.WIDTH.ALLOWED_WEBSITES}
            height={SWITCH.HEIGHT.ALLOWED_WEBSITES}
            onChange={(value) =>
              dispatch(updateMoreOptionIsUseWebsiteChecked(value))
            }
            checked={isUseWebsiteChecked}
          />
          {t('use_websites_during_this_activity')}
        </label>
        {isUseWebsiteChecked && (
          <button
            className='min-w-max sm:w-24 h-fit py-1 px-2 text-[10px] bg-gray-500 text-white rounded-md  transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-gray-600 duration-300'
            onClick={() => {
              dispatch(updateMoreOptionShowAllowedURLModal(true));
            }}
          >
            {t('manage_url')}
          </button>
        )}
      </div>
      {isUseWebsiteChecked && (
        <div className='w-full max-h-48 pr-4 pt-0.5 flex flex-col gap-2 items-center justify-start overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
          {(activity?.allowed_urls ?? [])?.map((url: string, index: number) => (
            <div
              key={url}
              className='w-full flex items-center py-0.5 border-b border-gray-200'
            >
              <div className='w-16 text-xs  font-medium'>
                {`URL ${increment(index)}: `}
              </div>
              <div className='w-full pr-1 text-sm font-medium  rounded-md truncate'>
                {url}
              </div>
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Websites;
