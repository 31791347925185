import { PropsWithChildren, useEffect, useMemo } from 'react';
import Switch from 'react-switch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  DEFAULT_MAC_APPS_IF_ALLOWED_URLS_SET,
  DEFAULT_WINDOWS_APPS_IF_ALLOWED_URLS_SET,
  PLATFORMS
} from 'constants/general';
import {
  updateMoreOptionAllowedApps,
  updateMoreOptionIsUseApplicationsChecked
} from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import { SWITCH } from 'constants/tag';
import { AutoCompletionTags } from 'components/common/AutoCompletionTags';
import { t } from 'i18next';
import { moreOptionsApplicationsSelector } from 'store/reducer/setting/selectors';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full md:w-[30%] flex flex-col'>{children}</div>
);

const Applications = () => {
  const dispatch = useAppDispatch();
  const {
    suggestions,
    platform,
    activity,
    isUseApplicationsChecked,
    isUseWebsiteChecked
  } = useAppSelector(moreOptionsApplicationsSelector);

  const { allowed_apps, applications } = useMemo(
    () => ({
      allowed_apps: activity?.allowed_apps ?? [],
      applications: (activity?.allowed_apps ?? []).map((app: string) => ({
        id: app,
        text: app
      }))
    }),
    [activity]
  );

  useEffect(() => {
    dispatch(
      updateMoreOptionIsUseApplicationsChecked(Boolean(allowed_apps.length))
    );
  }, []);

  useEffect(() => {
    if (isUseWebsiteChecked && !allowed_apps.length) {
      dispatch(updateMoreOptionIsUseApplicationsChecked(true));
      const default_allowed_apps: string[] =
        platform === PLATFORMS.WIN
          ? DEFAULT_WINDOWS_APPS_IF_ALLOWED_URLS_SET
          : DEFAULT_MAC_APPS_IF_ALLOWED_URLS_SET;
      dispatch(updateMoreOptionAllowedApps(default_allowed_apps));
    }
  }, [isUseWebsiteChecked]);

  return (
    <Wrapper>
      <div className='w-full flex gap-2.5 py-1'>
        <label
          data-test={ATTRIB.TEST.SWITCH_USE_APPS}
          className='flex gap-2 text-xs lg:text-sm font-medium  text-left line-clamp-2 cursor-pointer'
        >
          <Switch
            width={SWITCH.WIDTH.ALLOWED_APPLICATIONS}
            height={SWITCH.HEIGHT.ALLOWED_APPLICATIONS}
            onChange={(value) => {
              dispatch(updateMoreOptionIsUseApplicationsChecked(value));
              !value && dispatch(updateMoreOptionAllowedApps([]));
            }}
            checked={isUseApplicationsChecked}
          />
          {t('use_applications_during_this_activity')}
        </label>
      </div>
      {isUseApplicationsChecked && (
        <AutoCompletionTags
          tags={applications}
          suggestions={suggestions.map((item: string) => ({
            id: item.toString()?.toLowerCase(),
            text: item.toString()
          }))}
          setTags={(apps) =>
            dispatch(updateMoreOptionAllowedApps(apps.map((app) => app.text)))
          }
          handleAddTag={(app) => {
            if (platform === PLATFORMS.MOB) {
              const shouldInstalledApp = suggestions.some(
                (suggestion) => suggestion?.toLowerCase() === app.text
              );
              shouldInstalledApp &&
                dispatch(
                  updateMoreOptionAllowedApps([
                    ...applications.map((app) => app.text),
                    app.text
                  ])
                );
            } else {
              dispatch(
                updateMoreOptionAllowedApps([
                  ...applications.map((app) => app.text),
                  app.text
                ])
              );
            }
          }}
          placeholder={t('press_enter_to_add_new_app')}
        />
      )}
    </Wrapper>
  );
};

export default Applications;
