import classNames from 'classnames';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import OverlaySpinner from 'components/OverlaySpinner';
import RoutineSuggestionsWizard from 'components/routine-suggestions/routine-suggestions-wizard';
import EditSuggestionsModal from 'components/routine-suggestions/routine-suggestions-wizard/suggestions/EditSuggestionsModal';
import { ROUTES } from 'constants/routes';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getHabitPacks } from 'store/reducer/habit-pack/extra';
import { routineSuggestionsSelector } from 'store/reducer/setting/selectors';
import { getUserDetails } from 'store/reducer/user/extra';
import { loadMetaDataForRoutineSuggestion } from 'utils/focusBear_apps';
import { isDarkModeActivated } from 'utils/validation';

const RoutineSuggestions = () => {
  const dispatch = useAppDispatch();
  const {
    themeMode,
    isTokenAddedToInterceptor,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal,
    language
  } = useAppSelector(routineSuggestionsSelector);

  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  useEffect(() => {
    loadMetaDataForRoutineSuggestion();
  }, []);

  useEffect(() => {
    isTokenAddedToInterceptor &&
      Promise.allSettled([
        dispatch(getUserDetails()),
        dispatch(getHabitPacks({ featured_for_onboarding: true, language }))
      ]);
  }, [isTokenAddedToInterceptor]);

  return isTokenAddedToInterceptor ? (
    <section
      className={classNames(
        'w-screen h-screen flex flex-col items-center justify-center p-2 sm:p-3 md:p-6 lg:p-8 overflow-x-hidden overflow-y-auto scrollbar-thin',
        {
          'bg-gray-500 text-white': shouldActivateDarkMode,
          'bg-whiteLace text-black': !shouldActivateDarkMode
        }
      )}
    >
      <RoutineSuggestionsWizard />
      {(isSendingInstalledHabitPackId || areSuggestedHabitPacksInstalled) && (
        <OverlaySpinner title={t('updating')} />
      )}
      {window.location.pathname === ROUTES.WEBVIEW_ROUTINE_SUGGESTION && (
        <ToastContainer />
      )}
      {showEditSuggestionsModal && <EditSuggestionsModal />}
    </section>
  ) : (
    <InitEmbeddedServices />
  );
};

export default RoutineSuggestions;
