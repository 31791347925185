import { useEffect } from 'react';
import _ from 'lodash';
import { USE_DEBOUNCE_RESIZE_DELAY } from 'constants/general';

const useDebouncedResize = (onResize?: () => void, debounceDelay?: number) => {
  const handleResize = _.debounce(() => {
    onResize?.();
  }, debounceDelay ?? USE_DEBOUNCE_RESIZE_DELAY.DEFAULT);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return null;
};

export default useDebouncedResize;
