import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import TodoFocusEndListOfTasks from 'components/todo-focus-end/TodoFocusEndListOfTasks';
import { SubmitTaskPayload, ToDoPlayerTask } from 'interfaces';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import { toDoFocusEndSelector } from 'store/reducer/user/selectors';
import { init_focusBear_apps } from 'utils/focusBear_apps';
import { getTodoPlayerTaskDuration, safeStringify } from 'utils/support';
import * as Sentry from '@sentry/react';
import { isValidToDoPlayerTaskArray } from 'utils/todoUtil';
import { DEFAULT_INVALID_FOCUS_END_TASK_INDEX } from 'constants/general';

export const ToDoFocusEndContext = createContext<{
  incomingTasks: SubmitTaskPayload[] | null;
  setIncomingTasks?: Dispatch<SetStateAction<SubmitTaskPayload[] | null>>;
}>({ incomingTasks: [] });

const ToDoFocusEnd = () => {
  const isTokenAddedToInterceptor = useAppSelector(toDoFocusEndSelector);
  const [incomingTasks, setIncomingTasks] = useState<
    SubmitTaskPayload[] | null
  >([]);

  window.loadTasks = useCallback((data) => {
    init_focusBear_apps(data);

    //@Description: This will provide support for current issues in desktop applications.
    const mixed_data: any = data;
    const tasks =
      mixed_data.tasks ??
      mixed_data?.tasks?.tasks ??
      mixed_data.tasks[DEFAULT_INVALID_FOCUS_END_TASK_INDEX] ??
      null;

    if (tasks && !isValidToDoPlayerTaskArray(tasks)) {
      Sentry.captureMessage('Invalid tasks array structure', {
        level: 'error',
        extra: { data: safeStringify(tasks) }
      });
    }

    setIncomingTasks(
      tasks
        ? (tasks ?? []).map((task: ToDoPlayerTask) => ({
            ...task,
            focusedDuration: task.focusedDuration
              ? parseInt(task.focusedDuration.toString())
              : getTodoPlayerTaskDuration(
                  (data?.tasks ?? []).length,
                  data.total_duration,
                  task.duration
                )
          }))
        : null
    );
  }, []);

  return (
    <ToDoFocusEndContext.Provider
      value={useMemo(
        () => ({ incomingTasks, setIncomingTasks }),
        [incomingTasks]
      )}
    >
      {isTokenAddedToInterceptor ? (
        <TodoFocusEndListOfTasks />
      ) : (
        <InitEmbeddedServices />
      )}
    </ToDoFocusEndContext.Provider>
  );
};

export default ToDoFocusEnd;
