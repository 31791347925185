import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import TabLayout from 'components/common/TabLayout';
import { REACT_KEY } from 'constants/general';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { suggestionsSelector } from 'store/reducer/setting/selectors';
import { getRoutineActivitiesFromSuggestions } from 'utils/support';
import RoutineSuggestions from './RoutineSuggestions';
import SuggestionsActions from './SuggestionsActions';
import { captureEvents } from 'utils/events';
import { POST_HOG_EVENT } from 'constants/events';

const Suggestions = ({
  setStep
}: {
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  const {
    routineSuggestions,
    routines,
    areSuggestedHabitPacksInstalled,
    userId,
    userEmail,
    platform,
    areSuggestionsSortedByDuration
  } = useAppSelector(suggestionsSelector);

  const shouldSelectBothRoutines = routines.every((routine) => routine.value);
  const suggestions = useMemo(
    () => routineSuggestions ?? [],
    [routineSuggestions]
  );
  const { morning_activities, evening_activities } = useMemo(
    () =>
      getRoutineActivitiesFromSuggestions(
        suggestions,
        areSuggestionsSortedByDuration
      ),
    [routineSuggestions]
  );

  return (
    <div className='min-w-full h-fit max-h-[calc(100%)] flex flex-col gap-2 pt-12 px-3 sm:px-6 shadow rounded-lg animate-fadeIn overflow-y-auto'>
      {(routineSuggestions ?? [])?.length ? (
        <>
          {shouldSelectBothRoutines ? (
            <TabLayout
              tabs={[
                <RoutineSuggestions
                  key={REACT_KEY.MORNING_ROUTINE_SUGGESTION}
                  activities={morning_activities}
                />,
                <RoutineSuggestions
                  key={REACT_KEY.EVENING_ROUTINE_SUGGESTION}
                  activities={evening_activities}
                />
              ]}
            />
          ) : (
            <RoutineSuggestions
              key={REACT_KEY.EVENING_ROUTINE_SUGGESTION}
              activities={suggestions}
            />
          )}
          {!areSuggestedHabitPacksInstalled ? (
            <SuggestionsActions
              morning_activities={morning_activities}
              evening_activities={evening_activities}
            />
          ) : null}
        </>
      ) : (
        <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
          <p className='text-sm sm:text-base md:text-lg text-center px-6 sm:px-12 md:px-20'>
            {t(
              'routine_suggestion.we_couldnt_find_any_habits_that_match_your_goals'
            )}
          </p>
          <ButtonFocusBear
            title={t('routine_suggestion.explore_habit_packs')}
            onClick={() => {
              captureEvents(
                POST_HOG_EVENT.ROUTINE_SUGGESTIONS_EXPLORE_HABIT_PACKS_BUTTON_CLICKED,
                userEmail,
                { platform, userId }
              );
              setStep((prev) => ++prev);
            }}
            additionalStyles='my-2'
          />
        </div>
      )}
    </div>
  );
};

export default Suggestions;
