import InputTime from 'components/common/inputs/InputTime';
import { SCREEN, TIME_FORMAT_24H } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cutoffTimeForNonHighPriorityActivitiesSelector } from 'store/reducer/setting/selectors';
import { updateCutoffTimeForNonHighPriority } from 'store/reducer/setting/slice';
import { useWindowSize } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';

const CutoffTimeForNonHighPriorityActivities = () => {
  const dispatch = useAppDispatch();
  const {
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation: { isValid, errorMessages }
  } = useAppSelector(cutoffTimeForNonHighPriorityActivitiesSelector);
  const { width: screenWidth } = useWindowSize();
  const [resetKey, setResetKey] = useState(uuid()); // Unique key to forcefully reset the input when any value is cleared

  return (
    <InputTime
      key={resetKey}
      selectedDate={cutoff_time_for_non_high_priority_activities ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateCutoffTimeForNonHighPriority(value));
        if (!value) {
          setResetKey(uuid());
        }
      }}
      errorMessages={errorMessages.cutoffTime}
      validateTime={() => !isValid.cutoffTime}
      label={t('what_time_do_you_get_off_tech_in_the_evening')}
      tooltipMessage={t(
        'explanation_cutoff_time_for_standard_priority_activities',
        {
          cutoff_time: cutoff_time_for_non_high_priority_activities
            ? moment(
                cutoff_time_for_non_high_priority_activities,
                TIME_FORMAT_24H
              ).format('h:mm a')
            : t('start_winding_down_time')
        }
      )}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      toolTipAlignment={screenWidth < SCREEN.WIDTH.SMALL ? 'right' : 'left'}
      labelStyles='text-sm xs:text-base sm:text-lg'
    />
  );
};

export default CutoffTimeForNonHighPriorityActivities;
