import { PLATFORMS, ROUTINE, TIME_FORMAT_24H } from 'constants/general';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingWrapperSelector } from 'store/reducer/setting/selectors';
import BreakFrequency from './BreakFrequency';
import CutoffTimeForNonHighPriorityActivities from './CutoffTimeForNonHighPriorityActivities';
import ShutDownTime from './ShutDownTime';
import StartUpTime from './StartUpTime';
import TimingSummary from './TimingSummary';
import AddFreeTimeHabitButton from '../TabBodyHeader/TabBodyHeaderWidgets/AddFreeTimeHabitButton';
import moment from 'moment';
import { validateRoutineTimes } from 'utils/validation';
import { updateRoutineTimeValidation } from 'store/reducer/setting/slice';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit max-h-full flex flex-col gap-1.5 sm:gap-2 md:gap-3 px-2 py-4 overflow-y-auto scrollbar-thin relative'>
    {children}
  </div>
);

const Timing = () => {
  const dispatch = useAppDispatch();
  const {
    platform,
    customRoutine,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes,
    startup_time
  } = useAppSelector(timingWrapperSelector);

  useEffect(() => {
    const validationResult = validateRoutineTimes(
      startup_time,
      shutdown_time,
      cutoff_time,
      evening_activities
    );
    dispatch(updateRoutineTimeValidation(validationResult));
  }, [startup_time, shutdown_time, cutoff_time, evening_activities]);

  const shouldSupportWebAndDesktopApps = useMemo(
    () => [PLATFORMS.MAC, PLATFORMS.WIN, PLATFORMS.WEB].includes(platform),
    [platform]
  );
  const shouldAllowAddFreeTimeHabit =
    cutoff_time &&
    moment(cutoff_time, TIME_FORMAT_24H).diff(
      moment(shutdown_time, TIME_FORMAT_24H),
      'seconds'
    ) > 0 &&
    user_focus_modes.length &&
    evening_activities?.every(
      (activity) =>
        !activity?.allowed_focus_mode_id &&
        !activity?.show_saved_distracting_websites
    );

  return (
    <Wrapper>
      {shouldAllowAddFreeTimeHabit ? <AddFreeTimeHabitButton /> : null}
      <StartUpTime />
      <ShutDownTime />
      <CutoffTimeForNonHighPriorityActivities />
      {shouldSupportWebAndDesktopApps && customRoutine !== ROUTINE.TIMING ? (
        <BreakFrequency />
      ) : null}
      {/* {shouldBeMobileApp && <MobileApplicationsBreak />} @Description, temporary disabled for mobile apps */}

      <TimingSummary />
    </Wrapper>
  );
};

export default Timing;
