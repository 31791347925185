import { MinusCircle } from 'assets/icons/MinusCircle';
import PauseFilled from 'assets/icons/PauseFilled';
import PlayFilled from 'assets/icons/PlayFilled';
import PlusCircle from 'assets/icons/PlusCircle';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import COLOR from 'constants/color';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import {
  DEFAULT_TODO_TASK_DURATION,
  TIME_UNIT_CONVERT_FACTOR,
  TO_DO_PLAYER_TASK_BTN_DURATION,
  TO_DO_SUB_TASK_WHERE
} from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';
import { selectedTaskActionsSelector } from 'store/reducer/to-do/selectors';
import {
  updatePlayerSelectedTask,
  updatePlayerTasks,
  updateShowTodoSubTasksModal
} from 'store/reducer/to-do/slice';
import { decrement } from 'utils/support';
import PlayerIntention from '../PlayerIntention';
import PlayerTaskStatus from '../PlayerTaskStatus';
import { DEFAULT_PLAYER_CONFIG } from 'assets/data';
import { useState, useEffect } from 'react';

const SelectedTaskActions = () => {
  const dispatch = useAppDispatch();
  const player = useAppSelector(selectedTaskActionsSelector);
  const { playerData, setPlayerData } = useContext(ToDoPlayerContext);
  const shouldDisablePlayAndPauseBtn = !player.selectedTask?.duration;
  const shouldDisableDecrementMinuteBtn =
    (player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION) <=
    DEFAULT_TODO_TASK_DURATION;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 60);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 60);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewSubTasks = () => {
    dispatch(
      updateTodoSubTasks({
        title: player.selectedTask?.title,
        subTasks: player.selectedTask?.subtasks ?? [],
        isNewTask: true,
        taskId: player.selectedTask?.id,
        where: TO_DO_SUB_TASK_WHERE.TO_DO_PLAYER
      })
    );
    dispatch(updateShowTodoSubTasksModal(true));
  };

  const handleDuration = (action?: string) => {
    const selectedTaskId = player.selectedTask?.id;
    const taskFound = player.tasks.find(
      (playerTask) => playerTask.id === selectedTaskId
    );
    const currentDuration = taskFound?.duration ?? playerData.counter;

    let updatedPlayingTaskDuration = playerData.counter;
    let taskInTheListDuration = currentDuration;

    const isDecrementAction =
      action === TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_A_MINUTE;
    const isValidCurrentDuration =
      currentDuration > DEFAULT_PLAYER_CONFIG.counter;
    const shouldDecrement = isDecrementAction && isValidCurrentDuration;
    const timeChange = TIME_UNIT_CONVERT_FACTOR.ONE_MINUTE_AS_SECONDS;

    if (shouldDecrement) {
      updatedPlayingTaskDuration -= timeChange;
      taskInTheListDuration = Math.max(
        currentDuration - timeChange,
        DEFAULT_TODO_TASK_DURATION
      );
    } else {
      updatedPlayingTaskDuration += timeChange;
      taskInTheListDuration += timeChange;
    }

    if (player.selectedTask) {
      dispatch(
        updatePlayerSelectedTask({
          ...player.selectedTask,
          duration: updatedPlayingTaskDuration
        })
      );
    }

    isValidCurrentDuration &&
      dispatch(
        updatePlayerTasks(
          player.tasks.map((playerTask) =>
            playerTask.id === selectedTaskId
              ? {
                  ...playerTask,
                  duration: taskInTheListDuration
                }
              : playerTask
          )
        )
      );
  };

  if (isSmallScreen) {
    return (
      <div className='w-full h-screen flex items-center justify-center'>
        <div className='text-center'>
          <p className='text-sm font-light'>
            Time
            <br />
            left:
          </p>
          <p className='text-xs mt-2'>
            {moment()
              .startOf('day')
              .add(playerData.counter, 'seconds')
              .format('mm')}
            {' min'}
          </p>
          <p className='text-xs mt-2 font-bold'>{player.selectedTask?.title}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='w-full flex flex-col gap-3'>
        {player.intention && <PlayerIntention />}
        <div className='w-full flex items-center gap-2'>
          <p className='w-[55%] text-right text-3xl sm:text-4xl md:text-5xl font-digital tracking-widest'>
            {moment()
              .startOf('date')
              .add(playerData.counter, 'seconds')
              .format(
                playerData.counter >=
                  TIME_UNIT_CONVERT_FACTOR.ONE_HOUR_AS_SECONDS
                  ? 'hh:mm:ss'
                  : 'mm:ss'
              )}
          </p>
          <button
            disabled={shouldDisablePlayAndPauseBtn}
            className='outline-none if_you_finish_the_task_early'
            onClick={() =>
              setPlayerData?.((prev) => ({
                ...prev,
                isPlayingStarted: prev.isPlaying ? prev.isPlayingStarted : true,
                isPlaying: !prev.isPlaying,
                counter: prev.isPlayingStarted
                  ? prev.counter
                  : decrement(prev.counter)
              }))
            }
          >
            {playerData.isPlaying ? (
              <PauseFilled
                fill={
                  shouldDisablePlayAndPauseBtn ? COLOR.GRAY_5OO : COLOR.BLACK
                }
              />
            ) : (
              <PlayFilled
                fill={
                  shouldDisablePlayAndPauseBtn ? COLOR.GRAY_5OO : COLOR.BLACK
                }
              />
            )}
          </button>
          <div className='flex flex-col gap-1 items-center animate-fadeIn'>
            <button onClick={() => handleDuration()}>
              <PlusCircle
                styles='w-4 h-auto'
                fill={COLOR.BLACK}
                fillDarkMode={COLOR.BLACK}
              />
            </button>
            <button
              disabled={shouldDisableDecrementMinuteBtn}
              onClick={() =>
                handleDuration(
                  TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_A_MINUTE
                )
              }
            >
              <MinusCircle
                fill={
                  shouldDisableDecrementMinuteBtn ? COLOR.GRAY_4OO : COLOR.BLACK
                }
                styles='w-4 h-auto'
              />
            </button>
          </div>
        </div>
        <p className='line-clamp-2 text-center px-2 text-sm xs:text-base md:text-lg'>
          {player?.selectedTask?.title}
        </p>
        {player.selectedTask && !playerData.isPlaying && (
          <div className='w-fit flex items-center gap-2 self-center animate-fadeIn'>
            <ButtonFocusBear
              title={t('to_do_procrastinate.view_sub_tasks')}
              onClick={handleViewSubTasks}
              size={BTN_FB_SIZE.SMALL}
              variant={BTN_FB_VARIANT.TERTIARY}
              additionalStyles='min-w-max'
            />

            <PlayerTaskStatus task={player.selectedTask} />
          </div>
        )}
      </div>
      <div className='w-full flex items-center justify-between px-4 py-2'>
        <p className='min-w-max bg-gray-600 text-white px-2 py-1 rounded-l-md text-[0.6rem] shadow font-medium'>{`${t('to_do_player.start')}: ${moment(player.selectedTask?.startTime, 'h:mm a').format('h:mm a')}`}</p>
        <progress
          className='w-full h-5 [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:bg-orange-400 [&::-moz-progress-bar]:bg-orange-400'
          value={
            (player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION) -
            playerData.counter
          }
          max={player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION}
        ></progress>
        <p className='min-w-max bg-gray-600 text-white px-2 py-1 rounded-r-md text-[0.6rem] shadow font-medium'>{`${t('to_do_player.end')}: ${moment(player.selectedTask?.endTime, 'h:mm a').format('h:mm a')}`}</p>
      </div>
    </>
  );
};
export default SelectedTaskActions;
