import InputTime from 'components/common/inputs/InputTime';
import { TIME_FORMAT_24H } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingSelector } from 'store/reducer/setting/selectors';
import { updateShutdownTime } from 'store/reducer/setting/slice';

const ShutDownTime = () => {
  const dispatch = useAppDispatch();
  const {
    cutoff_time_for_non_high_priority_activities,
    shutdown_time,
    routineTimeValidation: { isValid, errorMessages }
  } = useAppSelector(timingSelector);

  return (
    <InputTime
      selectedDate={shutdown_time ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateShutdownTime(value));
      }}
      validateTime={() => !isValid.shutdownTime}
      errorMessages={errorMessages.shutdownTime}
      label={t('what_time_do_you_finish_work')}
      tooltipMessage={t('explanation_shutdown_time', {
        shutdown_time: shutdown_time
          ? moment(shutdown_time, TIME_FORMAT_24H).format('hh:mm a')
          : t('finish_work_time'),
        cutoff_time: cutoff_time_for_non_high_priority_activities
          ? moment(
              cutoff_time_for_non_high_priority_activities,
              TIME_FORMAT_24H
            ).format('hh:mm a')
          : moment(shutdown_time, TIME_FORMAT_24H).format('hh:mm a')
      })}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      labelStyles='text-sm xs:text-base sm:text-lg'
    />
  );
};

export default ShutDownTime;
