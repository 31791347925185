import { CUSTOM_ROUTINE_TRIGGER, THEME_OPTION } from 'constants/enum';
import {
  ACTIVITY_TYPES,
  INDEX,
  NODE_ENV,
  PRIORITY_OPTION,
  QUERIES,
  STRIPE_CUSTOMER_ID_CHARACTER,
  TIME_FORMAT_24H
} from 'constants/general';
import { NewTaskFormValues, ValidationErrorNewTask } from 'interfaces';
import { ActivityType } from 'interfaces/commonInterface';
import {
  CustomRoutineType,
  ROUTINE_TIME_VALIDATION,
  SettingsType
} from 'interfaces/settingInterface';
import { EMBEDDED_PAGE, ROUTES } from 'constants/routes';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'services/i18n';

export const validateNewToDoTask = (values: NewTaskFormValues) => {
  const errors: ValidationErrorNewTask = {};

  if (!values.title) {
    errors.title = '*';
  }
  if (!values.due_date) {
    errors.due_date = '*';
  }
  if (!values.eisenhower_quadrant.value) {
    errors.eisenhower_quadrant = '*';
  }
  return errors;
};

export const validateInputDuration = (
  value: string,
  maxValue: number,
  type?: string
) => {
  if (parseFloat(value) < 0) {
    return { error: true, data: -1 };
  }

  if (type === ACTIVITY_TYPES.BREAKING) {
    return parseInt(value) > maxValue
      ? { error: true, data: -1 }
      : { error: false, data: Math.abs(parseInt(value)) };
  } else {
    return parseFloat(value) > maxValue
      ? { error: true, data: -1 }
      : {
          error: false,
          data: Math.abs(parseInt(value))
        };
  }
};

export const validateUrl = (value: string) =>
  /(http|https)?:\/\/([\w\d-]+\.)+\w{2,}(\/.+)?$/.test(value);

export const isRouteEmbedded = [
  ROUTES.WEBVIEW_CHAT,
  ROUTES.WEBVIEW_MOTIVATIONAL_SUMMARY,
  ROUTES.WEBVIEW_STATS,
  ROUTES.WEBVIEW_TODO_LIST,
  ROUTES.WEBVIEW_TOOLS_TODO_LIST,
  EMBEDDED_PAGE,
  ROUTES.WEBVIEW_MOBILE_MOTIVATIONAL_SUMMARY,
  ROUTES.WEBVIEW_COURSE,
  ROUTES.WEBVIEW_FOCUS_END,
  ROUTES.WEBVIEW_TO_DO_PLAYER,
  ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION,
  ROUTES.WEBVIEW_ROUTINE_SUGGESTION
].includes(window.location.pathname);

export const isDarkModeActivated = (mode: THEME_OPTION) =>
  mode === THEME_OPTION.DARK;

export const isAppInProduction = () =>
  process.env.NODE_ENV === NODE_ENV.PRODUCTION;

export const isEmbeddedTodoList = (pathname: string) =>
  [ROUTES.WEBVIEW_TODO_LIST, ROUTES.WEBVIEW_TOOLS_TODO_LIST].includes(pathname);

export const isStripeCustomerId = (arg: string) =>
  /^cus_[a-zA-Z0-9]+/i.test(arg) && arg.length === STRIPE_CUSTOMER_ID_CHARACTER;

export const hasCompletionRequirement = (habit: ActivityType) =>
  Boolean(habit?.completion_requirements);

export const isValidImageURL = (url: string) => {
  if (typeof url !== 'string') {
    return false;
  }
  return !!url.match(/^http.*\.(jpeg|jpg|png)$/);
};

export const checkEmbeddedQuery = (queriesString: string) => {
  const queries = queriesString.split('&');
  if (queries.length) {
    const queriesArray = queries[INDEX.ZERO].replace('?', '').split('=');
    return (
      queriesArray?.[INDEX.ZERO] === QUERIES.EMBEDDED &&
      queriesArray?.[INDEX.ONE] === 'true'
    );
  }
  return false;
};

export const isCurrentPlayingActivityFromChoice = (
  activities: ActivityType[],
  currentPlayingActivity: ActivityType
) => {
  let result: {
    parent_activity_id: string | null;
    choice_id: string | null;
  } = { parent_activity_id: null, choice_id: null };
  // change so that all sequence types can have choices
  activities?.forEach((activity: ActivityType) => {
    const choice = activity?.choices?.find(
      (choice) => choice.id === currentPlayingActivity?.id
    );
    if (choice) {
      result = {
        parent_activity_id: activity.id,
        choice_id: choice.id
      };
    } else if (activity.id === currentPlayingActivity?.id) {
      result = {
        parent_activity_id: activity.id,
        choice_id: null
      };
    }
  });
  return result;
};

export const isActivityFilterByAll = (
  activity: ActivityType,
  searchWord: string,
  priority: string,
  daysOfWeek?: string[]
) => {
  const isSubStringFound =
    searchWord === ''
      ? true
      : String(activity?.name ?? '')
          .toLowerCase()
          .includes(searchWord.toLowerCase());
  const isPrioritized =
    priority === ''
      ? true
      : (activity?.priority ?? PRIORITY_OPTION.STANDARD) === priority;
  const selectedDays =
    activity.days_of_week?.filter((day) => daysOfWeek?.includes(day)) ?? [];
  const areSelectedDaysExists =
    daysOfWeek && daysOfWeek.length > 0 ? selectedDays.length > 0 : true;

  return isSubStringFound && isPrioritized && areSelectedDaysExists;
};

/**
 * @returns boolean by comparing the current user settings
 * with the previous/existing one(i.e the previous settings
 * might come from mac,win ... apps)
 */
export const compareSettings = (
  currentSettings: SettingsType,
  previousSettings: SettingsType
) => {
  let has_edited_settings = false;
  const currentSetting: any = { ...currentSettings }; // eslint-disable-line @typescript-eslint/no-explicit-any
  const previousSetting: any = { ...previousSettings }; // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key in previousSetting) {
    if (
      key === ACTIVITY_TYPES.MORNING ||
      key === ACTIVITY_TYPES.EVENING ||
      key === ACTIVITY_TYPES.BREAKING ||
      key === ACTIVITY_TYPES.STANDALONE
    ) {
      if (currentSetting[key].length !== previousSetting[key].length) {
        has_edited_settings = true;
        break;
      } else {
        for (const subKey in previousSetting[key]) {
          const previous_setting_child = _.omit(previousSetting[key][subKey], [
            'id',
            'activity_sequence_id'
          ]);
          const current_setting_child = _.omit(currentSetting[key][subKey], [
            'id',
            'activity_sequence_id'
          ]);
          if (!_.isEqual(previous_setting_child, current_setting_child)) {
            has_edited_settings = true;
            break;
          } else {
            has_edited_settings = false;
          }
        }
        if (has_edited_settings) {
          break; //@Description: if any of the child has been edited, then break the outer loop
        }
      }
    } else {
      if (!_.isEqual(previousSetting[key], currentSetting[key])) {
        has_edited_settings = true;
        break;
      }
    }
  }
  return has_edited_settings;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isYoutubeURL = (url: string) =>
  url.match(
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu(?:be)?\.com\/(?:v\/|embed\/|shorts\/|watch(?:\/|\?v=))|youtu\.be\/)((?:\w|-){11})(?:\S+)?$/
  );

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const validateCustomRoutineTimeIntervals = (
  customRoutine?: CustomRoutineType
) => {
  const isInvalidTime = {
    isInvalidStartTime: false,
    isInvalidEndTime: false,
    errors: { startTime: [], endTime: [] }
  };

  if (!isValidTime(customRoutine?.start_time)) {
    isInvalidTime.isInvalidStartTime = true;
    isInvalidTime.errors.startTime = [i18n.t('errors.empty_time')];
  } else {
    isInvalidTime.isInvalidStartTime = moment(
      customRoutine?.start_time,
      TIME_FORMAT_24H
    ).isSameOrAfter(moment(customRoutine?.end_time, TIME_FORMAT_24H), 'minute');
    if (isInvalidTime.isInvalidStartTime) {
      isInvalidTime.errors.startTime = [i18n.t('validation.invalid_time')];
    }
  }

  if (!isValidTime(customRoutine?.end_time)) {
    isInvalidTime.isInvalidEndTime = true;
    isInvalidTime.errors.endTime = [i18n.t('errors.empty_time')];
  } else {
    isInvalidTime.isInvalidEndTime = moment(
      customRoutine?.end_time,
      TIME_FORMAT_24H
    ).isSameOrBefore(
      moment(customRoutine?.start_time, TIME_FORMAT_24H),
      'minute'
    );
    if (isInvalidTime.isInvalidEndTime) {
      isInvalidTime.errors.endTime = [i18n.t('validation.invalid_time')];
    }
  }

  return isInvalidTime;
};

export const validateScheduleCustomRoutines = (
  routines?: CustomRoutineType[]
) => {
  return !routines?.length
    ? false
    : routines?.some((routine) => {
        let isInvalidRoutine = false;
        if (routine.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE) {
          const isInvalidStartTime = moment(
            routine?.start_time,
            TIME_FORMAT_24H
          ).isSameOrAfter(moment(routine?.end_time, TIME_FORMAT_24H), 'minute');
          const isInvalidEndTime = moment(
            routine?.end_time,
            TIME_FORMAT_24H
          ).isSameOrBefore(
            moment(routine?.start_time, TIME_FORMAT_24H),
            'minute'
          );
          isInvalidRoutine =
            !routine.start_time ||
            !routine.end_time ||
            isInvalidStartTime ||
            isInvalidEndTime ||
            !routine.days_of_week?.length;
        }
        return !routine.name || isInvalidRoutine;
      });
};

export const isActivityCutoffTimeValid = (
  cutoff_time_for_doing_activity?: string,
  cutoff_time_for_non_high_priority_activities?: string
) => {
  if (!cutoff_time_for_non_high_priority_activities) {
    return true;
  }

  if (!cutoff_time_for_doing_activity) {
    return true;
  }

  const activityTime = moment(cutoff_time_for_doing_activity, TIME_FORMAT_24H);
  const nonHighPriorityTime = moment(
    cutoff_time_for_non_high_priority_activities,
    TIME_FORMAT_24H
  );

  return activityTime.isAfter(nonHighPriorityTime);
};

const isValidTime = (time?: string) => {
  return moment(time, TIME_FORMAT_24H, true).isValid();
};

export const validateRoutineTimes = (
  startupTime?: string,
  shutdownTime?: string,
  cutoffTime?: string,
  evening_activities?: ActivityType[]
) => {
  const startupMoment = moment(startupTime, TIME_FORMAT_24H, true);
  const shutdownMoment = moment(shutdownTime, TIME_FORMAT_24H, true);

  const validationResult: ROUTINE_TIME_VALIDATION = {
    isValid: {
      startupTime: true,
      shutdownTime: true,
      cutoffTime: true
    },
    errorMessages: {
      startupTime: [],
      shutdownTime: [],
      cutoffTime: []
    }
  };
  if (!startupMoment.isValid()) {
    validationResult.isValid.startupTime = false;
    validationResult.errorMessages.startupTime.push(
      i18n.t('validation.invalid_time_message')
    );
  }

  if (!shutdownMoment.isValid()) {
    validationResult.isValid.shutdownTime = false;
    validationResult.errorMessages.shutdownTime.push(
      i18n.t('validation.invalid_time_message')
    );
  }
  if (startupMoment.isValid() && shutdownMoment.isValid()) {
    if (!shutdownMoment.isAfter(startupMoment)) {
      validationResult.isValid.shutdownTime = false;
      validationResult.isValid.startupTime = false;

      validationResult.errorMessages.shutdownTime.push(
        i18n.t('errors.finish_work_time_must_be_after_wake_up_time')
      );
      validationResult.errorMessages.startupTime.push(
        i18n.t('errors.wake_up_time_must_be_before_finish_work_time')
      );
    }
  }

  if (cutoffTime) {
    const cutoffMoment = moment(cutoffTime, TIME_FORMAT_24H, true);
    if (!cutoffMoment.isValid()) {
      validationResult.isValid.cutoffTime = false;
      validationResult.errorMessages.cutoffTime.push(
        i18n.t('validation.invalid_time_message')
      );
    } else {
      const foundInvalidEveningActivityCutoffTime = (
        evening_activities ?? []
      ).some(
        (activity) =>
          !isActivityCutoffTimeValid(
            activity.cutoff_time_for_doing_activity,
            cutoffTime
          )
      );

      if (foundInvalidEveningActivityCutoffTime) {
        validationResult.isValid.cutoffTime = false;
        validationResult.errorMessages.cutoffTime.push(
          i18n.t(
            'errors.the_time_should_be_before_all_of_the_cutoff_times_for_evening_activities'
          )
        );
      }

      if (shutdownMoment.isValid() && startupMoment.isValid()) {
        const isCutoffTimeAfterMidNight =
          cutoffMoment.isBefore(shutdownMoment) &&
          cutoffMoment.isBefore(startupMoment);
        if (
          !(isCutoffTimeAfterMidNight || cutoffMoment.isAfter(shutdownMoment))
        ) {
          validationResult.isValid.cutoffTime = false;

          if (!cutoffMoment.isAfter(shutdownMoment)) {
            validationResult.errorMessages.cutoffTime.push(
              i18n.t('errors.get_off_tech_time_must_be_after_finish_work_time')
            );
          } else {
            validationResult.errorMessages.cutoffTime.push(
              i18n.t('errors.get_off_tech_time_must_be_after_wake_up_time')
            );
          }
        }
      }
    }
  }

  return validationResult;
};
