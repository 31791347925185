import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import classNames from 'classnames';
import {
  Attributes,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react';
import { isDarkThemeActivated } from 'utils/settingsUtil';
interface TextInputProps
  extends DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    Attributes {
  readonly type?: 'text';
  readonly additionalStyles?: string;
}

const TextInput = forwardRef(
  (
    { type = 'text', className, additionalStyles, ...rest }: TextInputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const shouldActivateDarkTheme = useMemo(() => isDarkThemeActivated(), []);

    useImperativeHandle(ref, () => ({
      getValue: () => inputRef.current?.value
    }));

    return (
      <input
        ref={inputRef}
        type={type}
        {...rest}
        className={classNames({
          [`${className}`]: Boolean(className),
          'border-orange-300 border outline-none rounded-md px-2 py-1 min-w-[30vw] ~text-sm/base text-black':
            !className,
          [`${additionalStyles}`]: true,
          'bg-white focus:bg-gray-100': !shouldActivateDarkTheme,
          'bg-gray-400': shouldActivateDarkTheme
        })}
        {...DEFAULT_HTML_ATTRIBUTES}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
