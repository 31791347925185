import { DEFAULT_TO_DO_TASK_STATUS } from 'assets/data';
import Dropdown from 'assets/icons/Dropdown';
import classNames from 'classnames';
import DropDown from 'components/common/DropDown';
import { TO_DO_STATUS } from 'constants/enum';
import {
  EISENHOWER_QUADRANT,
  EMPTY_STRING,
  TAB_INDEX
} from 'constants/general';
import { ROUTES } from 'constants/routes';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import { taskItemHeaderSelector } from 'store/reducer/to-do/selectors';
import { updateSelectedTaskIDs } from 'store/reducer/to-do/slice';
import { getToDoDefaultStatusLabel } from 'utils/support';
import { TaskItemContext } from '.';
import TaskProjects from './TaskProjects';

const TaskItemHeader = () => {
  const {
    taskItemState: { task, showTaskContents },
    setTaskItemState
  } = useContext(TaskItemContext);
  const dispatch = useAppDispatch();
  const { isUpdatingStatus, selectedTaskIDs } = useAppSelector(
    taskItemHeaderSelector
  );
  const [status, setStatus] = useState({
    label: getToDoDefaultStatusLabel(task?.status ?? EMPTY_STRING),
    value: task?.status ?? EMPTY_STRING
  });

  const toDoStatusOptions = task?.external_statuses?.length
    ? task.external_statuses?.map((status) => ({
        label: status.label,
        value: status.status_id
      }))
    : DEFAULT_TO_DO_TASK_STATUS;

  const isLoading =
    selectedTaskIDs.includes(task?.id ?? EMPTY_STRING) && isUpdatingStatus;
  const shouldEmbedTodoPlayer = window.location.pathname.includes(
    ROUTES.WEBVIEW_TO_DO_PLAYER
  );

  return (
    <div
      tabIndex={TAB_INDEX.TASK_COLLAPSE}
      className={classNames(
        'w-full cursor-pointer text-sm md:text-base flex items-center justify-between relative pl-7 gap-4 rounded-md',
        {
          'bg-red-200':
            String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DO,
          'bg-orange-200':
            String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DECIDE,
          'bg-green-200':
            String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELEGATE,
          'bg-blue-200':
            String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELETE
        }
      )}
    >
      <button
        onClick={() =>
          setTaskItemState?.((prev) => ({
            ...prev,
            showTaskContents: !prev.showTaskContents
          }))
        }
      >
        <Dropdown
          styles={`w-5 h-5 absolute bottom-[20%] -translate-y-[-20%] left-1 ${
            showTaskContents ? 'rotate-0' : '-rotate-90'
          }
          `}
        />
      </button>
      <div className='w-full flex flex-col gap-2 overflow-hidden'>
        <div className='w-full flex items-start justify-between flex-wrap p-2 rounded-md shadow text-[0.6rem]'>
          {task?.due_date && (
            <p className='pr-2 text-xs'>
              {`${t('to_do_procrastinate.due_date')} ${moment(
                task.due_date
              ).format('MMM DD, YYYY')}`}
            </p>
          )}
          <p className='bg-gray-600 text-white rounded-md shadow-md px-2 text-center leading-5 tracking-wider'>
            {`${t('priority')} ${task?.eisenhower_quadrant}`}
          </p>
          {task?.tags?.length ? <TaskProjects /> : null}
        </div>
        <div className='w-full flex items-center justify-between gap-1'>
          <p className='w-[70%] break-words'>{task?.title}</p>
          {!shouldEmbedTodoPlayer &&
            window.location.pathname !== ROUTES.WEBVIEW_TODO_LIST && (
              <DropDown
                title={EMPTY_STRING}
                value={status ?? undefined}
                options={toDoStatusOptions}
                handleChange={(data) => {
                  const result = data as DropDownSelectedValue;
                  task &&
                    dispatch(
                      updateSelectedTaskIDs([...selectedTaskIDs, task.id])
                    );
                  data &&
                    setStatus(() => {
                      task &&
                        dispatch(
                          updateToDoStatus({
                            todoTask: {
                              ...task,
                              status: result.value
                            }
                          })
                        );
                      return result;
                    });
                }}
                containerStyles='min-w-max flex flex-col text-xs self-start gap-1.5'
                isClearable={false}
                isDisabled={
                  task?.status === TO_DO_STATUS.COMPLETED || isLoading
                }
                isLoading={isLoading}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default TaskItemHeader;
