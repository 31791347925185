import classNames from 'classnames';
import ConfirmActionModal from 'components/common/ConfirmActionModal';
import SubTasksModal from 'components/dashboard/to-do/SubTasksModal';
import BrainDump from 'components/todo-player/brain-dump';
import PlayerAddTask from 'components/todo-player/player-add-task';
import PlayerSelectedTask from 'components/todo-player/player-selected-task/';
import PlayerTasksList from 'components/todo-player/player-tasks-list';
import PlayerSelectedTaskCompletionPopup from 'components/todo-player/PlayerSelectedTaskCompletionPopup';
import TotalDurationCounter from 'components/todo-player/TotalDurationCounter';
import { INTRO_TO_DO_PLAYER_STEPS } from 'constants/intro';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps } from 'react-joyride';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { playerContentsSelector } from 'store/reducer/to-do/selectors';
import { isDarkModeActivated } from 'utils/validation';
import PlayerIntention from './PlayerIntention';
import { updateIsUserOnboarding } from 'store/reducer/setting/slice';
import { TO_DO_STATUS } from 'constants/enum';

const PlayerContents = () => {
  const dispatch = useAppDispatch();
  const {
    showTodoSubTasksModal,
    player,
    themeMode,
    isUserOnboarding,
    showConfirmModal,
    showPlayerNewTaskModal
  } = useAppSelector(playerContentsSelector);
  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );
  const [startTour, setStartTour] = useState<{
    run: boolean;
    steps: { target: string; content: string; disableBeacon: boolean }[];
    isTourStartShowing: boolean;
    isLastTourSection: boolean;
  }>({
    run: false,
    steps: [],
    isTourStartShowing: false,
    isLastTourSection: false
  });
  const allTasksCompleted = player.tasks.every(
    (task) => task.status === TO_DO_STATUS.COMPLETED
  );
  const isPlayerTaskEmpty = player.tasks.length === 0;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 60);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 60);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isUserOnboarding) {
      if (showPlayerNewTaskModal && !startTour.isTourStartShowing) {
        const timer = setTimeout(() => {
          setStartTour((prev) => ({
            ...prev,
            run: true,
            steps: INTRO_TO_DO_PLAYER_STEPS('').SECOND,
            isTourStartShowing: true
          }));
        }, 500); // waiting the modal to finish the translation
        return () => clearTimeout(timer);
      } else if (!player.selectedTask && player.tasks.length) {
        setStartTour((prev) => ({
          ...prev,
          run: true,
          steps: INTRO_TO_DO_PLAYER_STEPS('').THIRD
        }));
      } else if (!startTour.isLastTourSection && player.selectedTask) {
        setStartTour((prev) => ({
          ...prev,
          run: true,
          steps: INTRO_TO_DO_PLAYER_STEPS(player.selectedTask?.title || '')
            .FOURTH,
          isLastTourSection: true
        }));
      } else {
        !startTour.isTourStartShowing &&
          setStartTour((prev) => ({
            ...prev,
            run: true,
            steps: INTRO_TO_DO_PLAYER_STEPS('').FIRST,
            isTourStartShowing: false
          }));
      }
    }
  }, [
    isUserOnboarding,
    showPlayerNewTaskModal,
    player.selectedTask,
    startTour.isLastTourSection
  ]);

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    if (
      data.step.target === '.if_you_finish_the_task_early' &&
      data.action === ACTIONS.RESET
    ) {
      setStartTour((prev) => ({ ...prev, run: false, steps: [] }));
      dispatch(updateIsUserOnboarding(false));
    } else {
      data.action === ACTIONS.RESET &&
        setStartTour((prev) => ({ ...prev, run: false, steps: [] }));
    }
  }, []);

  if (isSmallScreen) {
    const getMessage = () => {
      if (!isPlayerTaskEmpty && allTasksCompleted) {
        return '✅ 🥳';
      }
      if (
        isPlayerTaskEmpty ||
        (!player.selectedTask && !player.isSelectedTaskCompleted)
      ) {
        return t('to_do_player.whats_next');
      }
      if (player.isSelectedTaskCompleted) {
        return t('to_do_player.time_up_small_screen');
      }
      return '';
    };

    const message = getMessage();

    if (message) {
      return (
        <div className='w-full h-screen flex items-center justify-center'>
          <div
            className={`text-${message === '✅ 🥳' ? '2xl' : 'xs'} text-center`}
          >
            {message}
          </div>
        </div>
      );
    }
  }

  return (
    <div
      className={classNames(
        'w-full min-h-screen h-full flex flex-col items-center justify-center px-2 sm:px-16 md:px-36 xl:px-80 2xl:px-96 relative overflow-y-auto scrollbar-thin',
        {
          'bg-gray-600 text-white': shouldActivateDarkMode,
          'bg-white text-black': !shouldActivateDarkMode
        }
      )}
    >
      <button
        className={classNames(
          { hidden: !startTour.run }, // Hide the button only if the tour is not running
          'absolute top-[-10px] right-16',
          'hidden_minimize_button'
        )}
      ></button>
      {player.tasks?.length ? (
        <>
          <PlayerSelectedTask />
          {!isSmallScreen && <PlayerTasksList />}
        </>
      ) : (
        <div className='w-fit h-fit flex flex-col items-center gap-3'>
          {player.intention ? (
            <PlayerIntention intentionTextStyles='w-fit text-center here_is_the_intention text-lg sm:text-xl md:text-2xl' />
          ) : null}
          <h5
            className={`w-fit text-center ${shouldActivateDarkMode ? 'text-gray-200' : 'text-gray-400'}`}
          >
            {t('to_do_player.empty_tasks')}
          </h5>
        </div>
      )}
      {!isSmallScreen && (
        <>
          <PlayerAddTask />
          <BrainDump />
          <TotalDurationCounter />
          {player.isSelectedTaskCompleted && (
            <PlayerSelectedTaskCompletionPopup />
          )}
          {showTodoSubTasksModal && <SubTasksModal />}
          {showConfirmModal && <ConfirmActionModal />}
          <Joyride
            steps={startTour.steps}
            run={startTour.run}
            continuous
            callback={handleJoyrideCallback}
            scrollToFirstStep
            hideCloseButton
            disableCloseOnEsc
            disableOverlayClose
            locale={{
              last: 'Continue' // Change the label for the next button to "Continue"
            }}
          />
        </>
      )}
    </div>
  );
};

export default PlayerContents;
