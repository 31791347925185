import { useContext } from 'react';
import { NewTaskModalContext } from '.';
import { TO_DO_STATUS } from 'constants/enum';
import { useAppSelector } from 'store/hooks';
import { SearchResult } from './SearchResults';
import { recentTasksSelector } from 'store/reducer/to-do/selectors';
import { t } from 'i18next';
import classNames from 'classnames';
import { isDarkModeActivated } from 'utils/validation';

const RecentTasks = () => {
  const {
    newTaskModal: { isSearchTitleFocused, searchedTitle }
  } = useContext(NewTaskModalContext);
  const { recentToDos, themeMode, tasks } = useAppSelector(recentTasksSelector);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  const todos = recentToDos?.filter((todo) =>
    tasks.every((task) => task?.id !== todo?.id)
  );

  return (
    <>
      {!isSearchTitleFocused && !searchedTitle && todos.length ? (
        <p
          className={classNames('text-xs px-4 py-2 text-white rounded-t-md', {
            'mt-5': !searchedTitle,
            'mt-0': searchedTitle,
            'bg-gray-500': shouldActivateDarkMode,
            'bg-gray-600': !shouldActivateDarkMode
          })}
        >
          {t('to_do_player.recent_tasks')}
        </p>
      ) : null}
      {!isSearchTitleFocused &&
        !searchedTitle &&
        todos
          .filter((task) =>
            task.status ? task.status !== TO_DO_STATUS.COMPLETED : task
          )
          ?.map((task) => <SearchResult key={task.id} task={task} />)}
    </>
  );
};

export default RecentTasks;
