import logo from 'assets/images/logo.png';
import { CUSTOM_ROUTINE_TRIGGER, Mode } from 'constants/enum';
import { ActivityImagesDataType } from 'interfaces';
import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import { getActivityDuration } from 'utils/support';
import { hasCompletionRequirement } from 'utils/validation';
import { ActivityType } from 'interfaces/commonInterface';
import moment from 'moment';
import { TIME_FORMAT_24H } from 'constants/general';

interface RoutineActivitiesProps {
  activities?: ActivityType[];
  routineName: string;
  start_time?: string;
  end_time?: string;
  trigger?: string;
}

const ActivityImages = ({
  images,
  altName
}: {
  readonly images?: ActivityImagesDataType[];
  readonly altName: string;
}) => {
  return images?.length ? (
    <div className='flex flex-row justify-start y-[5px]'>
      {images
        .map((image, idx) => ({ image, idx }))
        .map(({ image, idx }) => (
          <img
            key={`${image.url}${idx}`}
            className='w-[150px] p-[10px]'
            src={image.url}
            alt={t('image_showing_how_to_do_habit', {
              habit_name: altName
            })}
          />
        ))}
    </div>
  ) : null;
};

const RoutineActivities = ({
  activities,
  routineName,
  start_time,
  end_time,
  trigger
}: RoutineActivitiesProps) => {
  return (
    <div className='border-2 border-black rounded-lg w-full m-auto py-4 px-4 break-after-page even:my-6'>
      <h3 className='text-lg font-bold mb-1 text-left flex'>
        {routineName}
        {trigger ? (
          <span className='ml-auto flex items-end gap-2'>
            <small>
              {trigger === CUSTOM_ROUTINE_TRIGGER.ON_DEMAND
                ? t('on_demand')
                : t('on_a_schedule')}
            </small>
            {start_time && end_time
              ? t('time_minutes', {
                  count: moment(end_time, TIME_FORMAT_24H).diff(
                    moment(start_time, TIME_FORMAT_24H),
                    'minutes'
                  )
                })
              : null}
          </span>
        ) : null}
      </h3>
      <div className='flex flex-col w-full justify-between text-base rounded-xl'>
        {(activities ?? []).map((habit) => {
          const duration = hasCompletionRequirement(habit)
            ? t('completion_requirements')
            : getActivityDuration(habit);
          return (
            <div
              key={habit.id}
              className='flex flex-col py-2 [&:not(:last-child)]:border-b-[1px] border-black'
            >
              <div className='flex text-sm font-semibold w-full justify-between'>
                <p>{habit.name}</p>
                <p>{duration}</p>
              </div>
              {habit.text_instructions && (
                <p className='text-left w-[100%]'>{habit.text_instructions}</p>
              )}
              <ActivityImages images={habit.image_urls} altName={habit.name} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Preview = () => {
  const { mode, currentSettings: settings } = useAppSelector(
    (state) => state.setting
  );

  return (
    <table className='w-[90%] lg:w-[51rem] h-full table table-auto flex-col items-center content-center'>
      <thead className='table-header-group'>
        <tr>
          <td>
            <img
              src={logo}
              alt='Focus Bear'
              className='w-[200px] h-[33px] mb-6 mt-8 m-auto'
            />
            <div className='w-full grid grid-cols-2 font-semibold text-base mb-6 border-y py-2 border-black'>
              <p>{t('starting_time', { time: settings.startup_time })}</p>
              <p>{t('ending_time', { time: settings.shutdown_time })}</p>
              <p>{t('break_every', { time: settings.break_after_minutes })}</p>
              {settings.cutoff_time_for_non_high_priority_activities && (
                <p className='min-w-max'>
                  {t('cutoff_time_for_standard_priority_activities')}:
                  {settings.cutoff_time_for_non_high_priority_activities}
                </p>
              )}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className='flex break-inside-avoid'>
          <td className='w-full h-fit max-h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
            {mode !== Mode.FREESTYLE && (
              <>
                <RoutineActivities
                  routineName={t('morning_habits')}
                  activities={settings?.morning_activities}
                />
                <RoutineActivities
                  routineName={t('evening_habits')}
                  activities={settings?.evening_activities}
                />
              </>
            )}
            <RoutineActivities
              routineName={t('break_activities')}
              activities={settings?.break_activities}
            />
            {settings.custom_routines?.map((routine) => (
              <RoutineActivities
                key={routine.id}
                routineName={routine.name}
                activities={routine.standalone_activities}
                start_time={routine.start_time}
                end_time={routine.end_time}
                trigger={routine.trigger}
              />
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Preview;
