import { useAuth0 } from '@auth0/auth0-react';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import DocumentMetaData from 'components/DocumentMetaData';
import OverlaySpinner from 'components/OverlaySpinner';
import SettingGenerator from 'components/setting-generator';
import EnableEditHabits from 'components/setting-generator/EnableEditHabits';
import { FLAG, Mode, THEME_OPTION } from 'constants/enum';
import {
  DASHBOARD,
  LANGUAGE,
  NUMBER_OF_TOAST_VISIBLE,
  PARAMS,
  PLATFORMS
} from 'constants/general';
import { FIRST_LOGIN_KEY, PAGES } from 'constants/routes';
import { useCallback, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTutorials } from 'store/reducer/course/extra';
import {
  getHabitPacks,
  getUserInstalledHabitPacks
} from 'store/reducer/habit-pack/extra';
import {
  getLibraryActivities,
  getUserSettings,
  syncPlatformCourses
} from 'store/reducer/setting/extra';
import { settingsPageSelector } from 'store/reducer/setting/selectors';
import {
  changeIsEmbeddedWebViewLoading,
  changeShowChoice,
  changeShowTabs,
  configureTabs,
  updateDeviceID
} from 'store/reducer/setting/slice';
import { getYouTubeURLMetaCacheData } from 'utils/cache';
import { loadSettingsData } from 'utils/focusBear_apps';
import { isMobilePlatform } from 'utils/settingsUtil';
import { isDashboard } from 'utils/support';

export default function SettingsPage() {
  const dispatch = useAppDispatch();
  const {
    loadingSchema,
    isEmbeddedWebViewLoading,
    platform,
    isSettingLoading,
    isTokenAddedToInterceptor,
    language,
    isPlatformCoursesSyncing,
    mode,
    flags,
    themeMode,
    areHabitPacksLoading
  } = useAppSelector(settingsPageSelector);
  const params = new URLSearchParams(window.location.search);
  const { user: auth0User } = useAuth0();
  const isEditHabitEnabled = !flags.includes(FLAG.DISABLE_EDIT_HABITS);
  const isSettingEmbedded =
    platform !== PLATFORMS.WEB || !window.location.href.includes(DASHBOARD);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    async function getSettingsPacksLibraryCoursesCacheData() {
      await Promise.allSettled([
        dispatch(getTutorials()),
        dispatch(getUserSettings()),
        getYouTubeURLMetaCacheData(),
        dispatch(getLibraryActivities())
      ]);
    }
    isEditHabitEnabled &&
      isTokenAddedToInterceptor &&
      [Mode.DEFAULT, Mode.CUSTOM].includes(mode) &&
      getSettingsPacksLibraryCoursesCacheData();
  }, [isTokenAddedToInterceptor, isEditHabitEnabled]);

  useEffect(() => {
    if (isEditHabitEnabled && isTokenAddedToInterceptor) {
      (flags.includes(FLAG.GEEK_MODE) ||
        isMobilePlatform() ||
        platform === PLATFORMS.WEB) &&
        Promise.allSettled([
          dispatch(getUserInstalledHabitPacks(false)),
          dispatch(
            getHabitPacks({
              language: language === LANGUAGE.GB ? 'en' : language,
              is_featured: true
            })
          ),
          dispatch(
            getHabitPacks({
              language: language === LANGUAGE.GB ? 'en' : language,
              marketplace_approval_status: true
            })
          )
        ]);
      isDashboard &&
        auth0User?.[FIRST_LOGIN_KEY] &&
        dispatch(syncPlatformCourses());
    }
  }, [isTokenAddedToInterceptor, isEditHabitEnabled]);

  const init = useCallback(() => {
    loadSettingsData();
    dispatch(changeShowChoice(false));
    dispatch(changeShowTabs(true));
    const routine = params.get(PARAMS.EDIT_SPECIFIC_ROUTINE);
    dispatch(updateDeviceID(params.get(PARAMS.DEVICE_ID) ?? undefined));
    routine && dispatch(configureTabs({ mode: Mode.CUSTOM, routine }));
    dispatch(changeIsEmbeddedWebViewLoading(true));
    const timeoutId = setTimeout(() => {
      dispatch(changeIsEmbeddedWebViewLoading(false));
      clearTimeout(timeoutId);
    }, 1500);
  }, []);

  return (
    <section
      className={`flex flex-col items-center ${
        isSettingEmbedded
          ? 'w-screen justify-center h-screen'
          : 'w-full justify-start h-[85vh]'
      } ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-600 py-2' : 'bg-transparent'
      } overflow-hidden`}
    >
      <DocumentMetaData pageName={PAGES.SETTINGS} />
      {!isDashboard &&
      (!isTokenAddedToInterceptor || isEmbeddedWebViewLoading) ? (
        <InitEmbeddedServices />
      ) : (
        <>{isEditHabitEnabled ? <SettingGenerator /> : <EnableEditHabits />}</>
      )}

      {(isSettingLoading ||
        loadingSchema ||
        areHabitPacksLoading ||
        isPlatformCoursesSyncing) && <OverlaySpinner />}
      {!isDashboard && (
        <ToastContainer
          limit={NUMBER_OF_TOAST_VISIBLE}
          draggable={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
        />
      )}
    </section>
  );
}
