import classNames from 'classnames';
import InputTime from 'components/common/inputs/InputTime';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { t } from 'i18next';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import { validateCustomRoutineTimeIntervals } from 'utils/validation';
import { CustomRoutineContext } from '..';

const TIME_VALIDATION_FIELDS = {
  START_TIME: 'start_time',
  END_TIME: 'end_time'
};

const RoutineTimeBoundaries = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);
  const isScheduledModeEnabled = useMemo(
    () => customRoutine?.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE,
    [customRoutine]
  );
  const [errorMessages, setErrorMessages] = useState({
    startTime: [],
    endTime: []
  });

  const isValidSchedule = useMemo(
    () => validateCustomRoutineTimeIntervals(customRoutine),
    [customRoutine]
  );

  useEffect(() => {
    const { errors } = isValidSchedule;
    (errors.startTime.length || errorMessages.endTime.length) &&
      setErrorMessages(errors);
  }, [isValidSchedule]);

  const handleValidation = useCallback(
    (feature: string) => {
      if (customRoutine?.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE) {
        const { isInvalidStartTime, isInvalidEndTime } = isValidSchedule;
        return feature === TIME_VALIDATION_FIELDS.START_TIME
          ? isInvalidStartTime
          : isInvalidEndTime;
      } else {
        return true;
      }
    },
    [isValidSchedule]
  );

  return (
    <div
      className={classNames('gap-8 animate-fadeIn', {
        'inline-flex': isScheduledModeEnabled,
        hidden: !isScheduledModeEnabled
      })}
    >
      <InputTime
        selectedDate={customRoutine?.start_time ?? ''}
        setSelectedDate={(value: string) => {
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.START_TIME,
                position: customRoutine?.position,
                value
              })
            );
        }}
        errorMessages={errorMessages.startTime}
        label={t('start_time')}
        wrapperStyles='min-w-max w-full flex flex-col py-2'
        labelStyles='text-xs'
        tooltipMessage={t('tooltip_custom_routine_schedule_start_time', {
          custom_routine_name: customRoutine?.name
        })}
        tooltipIconStyles='w-4 h-4 inline-flex ml-1 mb-0.5'
        validateTime={() => handleValidation(TIME_VALIDATION_FIELDS.START_TIME)}
      />
      <InputTime
        selectedDate={customRoutine?.end_time ?? ''}
        setSelectedDate={(value: string) => {
          customRoutine &&
            dispatch(
              updateCustomRoutine({
                feature: CUSTOM_ROUTINE_FEATURE.END_TIME,
                position: customRoutine?.position,
                value
              })
            );
        }}
        errorMessages={errorMessages.endTime}
        label={t('end_time')}
        wrapperStyles='min-w-max w-full flex flex-col py-2'
        labelStyles='text-xs'
        tooltipMessage={t('tooltip_custom_routine_schedule_end_time', {
          custom_routine_name: customRoutine?.name
        })}
        tooltipIconStyles='w-4 h-4 inline-flex ml-1 mb-0.5'
        validateTime={() => handleValidation(TIME_VALIDATION_FIELDS.END_TIME)}
      />
    </div>
  );
};

export default RoutineTimeBoundaries;
