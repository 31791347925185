import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import { SUGGESTED_PACK } from 'constants/enum';
import {
  ACTIVITY_TYPES,
  EMPTY_STRING,
  HABIT_SETTING_FEATURES,
  KEY_BOARD
} from 'constants/general';
import { ATTRIB } from 'constants/test';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateChoices, updateChoicesName } from 'store/reducer/modal/slice';
import { habitPackAndLibraryActivitiesAutocompleteInputSelector } from 'store/reducer/setting/selectors';
import { cloneHabit, updateSettingFeatures } from 'store/reducer/setting/slice';
import {
  getHabitPackActivitiesWithPackTitle,
  getMatchedActivities
} from 'utils/settingsUtil';
import { isDarkModeActivated } from 'utils/validation';
import { v4 as uuid } from 'uuid';
import ActivitySuggestion, { Suggestion } from './ActivitySuggestion';
import { addNewActivityButtonSelector } from 'store/reducer/setting/selectors';
import { newHabit } from 'store/reducer/setting/slice';
import { getNewHabitProps } from 'utils/support';
import { useMemo } from 'react';

interface Props {
  readonly name: string;
  readonly type: string;
  readonly position: number;
  readonly isEditingAllowed?: boolean;
  readonly customRoutinePosition?: number;
}

export default function HabitPackAndLibraryActivitiesAutocompleteInput({
  type,
  position,
  name,
  isEditingAllowed = true,
  customRoutinePosition
}: Props) {
  const dispatch = useAppDispatch();
  const {
    themeMode,
    tabs,
    activeTabIndex,
    customRoutine,
    currentSettings,
    routineSuggestionsSelectedRoutines,
    activityLibrary,
    choices,
    marketplaceApprovedPacks
  } = useAppSelector((state) => ({
    ...addNewActivityButtonSelector(state),
    ...habitPackAndLibraryActivitiesAutocompleteInputSelector(state)
  }));

  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  const [activity_type, sequence_id] = getNewHabitProps(
    tabs,
    activeTabIndex,
    currentSettings,
    customRoutine,
    routineSuggestionsSelectedRoutines
  );

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const marketplaceActivities = getHabitPackActivitiesWithPackTitle(
    marketplaceApprovedPacks
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSuggestions = (search: string) => {
    if (search.length > 2) {
      const matchedActivities = getMatchedActivities(
        marketplaceActivities,
        activityLibrary,
        search,
        inputRef.current?.value ?? EMPTY_STRING
      );

      const uniqueActivities = matchedActivities.filter(
        (activity, index, self) =>
          index ===
          self.findIndex(
            (t) => t.name === activity.name && t.duration === activity.duration
          )
      );

      setSuggestions(uniqueActivities);
    } else {
      setSuggestions([]);
    }
  };

  const handelSuggestedValue = ({ name, where }: Suggestion) => {
    if (where === SUGGESTED_PACK.CREATE_NEW_HABIT) {
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.NAME,
          value: name,
          customRoutinePosition
        })
      );
    } else {
      const templateActivity = (
        where === SUGGESTED_PACK.LIBRARY
          ? activityLibrary
          : marketplaceActivities
      ).find((activity) => activity.name === name);
      if (templateActivity) {
        if (type === ACTIVITY_TYPES.CHOICE) {
          const newChoices = [...choices];
          newChoices[position] = {
            id: uuid(),
            name: templateActivity.name,
            video_urls: templateActivity?.video_urls ?? [],
            log_quantity: templateActivity?.log_quantity ?? false,
            log_quantity_question: EMPTY_STRING
          };
          dispatch(updateChoices(newChoices));
        } else {
          dispatch(
            cloneHabit({
              templateActivity,
              newActivityPosition: position,
              type
            })
          );
        }
      }
    }
    return name;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY_BOARD.ENTER.KEY) {
      dispatch(
        newHabit({
          type: activity_type,
          sequence_id
        })
      );
    }
  };

  return (
    <div className='relative w-full'>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => handleSuggestions(value)}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        getSuggestionValue={handelSuggestedValue}
        renderSuggestion={(suggestion) => (
          <ActivitySuggestion suggestion={suggestion} />
        )}
        inputProps={{
          placeholder: t('placeholder.activity'),
          value: name,
          onChange: (_, { newValue }) => {
            if (type === ACTIVITY_TYPES.CHOICE) {
              dispatch(updateChoicesName({ position, value: newValue }));
            } else {
              dispatch(
                updateSettingFeatures({
                  type,
                  position,
                  feature: HABIT_SETTING_FEATURES.NAME,
                  value: newValue,
                  customRoutinePosition
                })
              );
            }
          },
          onKeyDown: handleKeyDown
        }}
        renderInputComponent={(inputProps) => {
          const testId =
            type === ACTIVITY_TYPES.CHOICE
              ? ATTRIB.TEST.INPUT_AUTO_SUGGESTION_ACTIVITY_CHOICE_NAME
              : ATTRIB.TEST.INPUT_AUTO_SUGGESTION_ACTIVITY_NAME;
          return (
            <input
              {...inputProps}
              className={`w-full outline-none py-1.5 rounded-md px-2 cursor-text text-black text-xs sm:text-sm ${
                shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'
              } focus:shadow-md`}
              data-test={testId}
              data-locator={ATTRIB.LOCATOR.HABIT_NAME}
              id={testId}
              ref={inputRef}
              disabled={!isEditingAllowed}
              {...DEFAULT_HTML_ATTRIBUTES}
            />
          );
        }}
        containerProps={{ className: 'auto-suggestion relative' }}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <div
            {...containerProps}
            className='w-full md:max-h-[40vh] bg-gray-100 rounded-md overflow-y-auto scrollbar-thin absolute z-[1000] shadow-md mt-2.5'
          >
            {children && (
              <>
                <p className='text-sm font-semibold ml-4 mt-3 mb-3'>
                  Activity Suggestions:
                </p>
                <div className='w-11/12 mx-auto mb-3'>{children}</div>
              </>
            )}
          </div>
        )}
        focusInputOnSuggestionClick={false}
      />
    </div>
  );
}
