import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetHabitPackPayload, UpdateApprovalStatusProps } from 'interfaces';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import * as Sentry from '@sentry/react';
import { changeIsSettingLoaded, updateError } from '../setting/slice';
import i18n from 'services/i18n';
import { getUserSettings } from '../setting/extra';
import { toast } from 'react-toastify';
import {
  EMPTY_STRING,
  HTTP_STATS_CODE,
  TOAST_AUTO_CLOSE_TIMEOUT
} from 'constants/general';
import { BASE_URL } from 'constants/routes';
import {
  setCurrentViewedPack,
  setFeaturedHabitPacks,
  setMarketplaceApprovedPacks,
  setUserCreatedHabitPacks,
  updateAreHabitPacksLoading,
  updateInstallingPack,
  updateIsCurrentHabitPackFetching,
  updateIsHabitPacksDeleting,
  updateIsSharedHabitPackFetching,
  updateIsUserInstalledPacksFetching,
  updateIsUserUpsertingPack,
  updateOnboardingHabitPacks,
  updateSharedViewedPack,
  updateUninstallingPack
} from './slice';
import { updateIsInquiryModalShown } from '../inquiry/slice';
import { AxiosError } from 'axios';
import { HabitPackType } from 'interfaces/commonInterface';
import { SettingsType } from 'interfaces/settingInterface';
import { t } from 'i18next';

export const getHabitPacks = createAsyncThunk(
  'habit_packs/get_habit_packs',
  async (params: GetHabitPackPayload, { dispatch }) => {
    try {
      const { searchTerm = EMPTY_STRING, ...rest } = params;
      dispatch(updateAreHabitPacksLoading(true));
      const { data } = await focusBearApi.get(Endpoints.HABIT_PACKS, {
        params: rest
      });
      if (params.is_featured) {
        dispatch(setFeaturedHabitPacks(data));
      } else if (params.marketplace_approval_status) {
        dispatch(
          setMarketplaceApprovedPacks(
            (data ?? []).filter(
              (pack: HabitPackType) =>
                pack.pack_name!.toLowerCase().includes(searchTerm) ||
                pack.creator_name!.toLowerCase().includes(searchTerm)
            ) ?? []
          )
        );
      } else if (params.user_id) {
        dispatch(setUserCreatedHabitPacks(data ?? []));
      } else if (params.featured_for_onboarding) {
        dispatch(updateOnboardingHabitPacks(data ?? []));
      }
      dispatch(updateAreHabitPacksLoading(false));
      return data ?? [];
    } catch (error) {
      dispatch(updateAreHabitPacksLoading(false));
      Sentry.captureException(JSON.stringify(error));
      // @FIX: Temporarily disable until the issue is resolved
      // !params.is_featured &&
      //   toast.error(i18n.t('market.could_not_fetch_habit_packs'));
    }
  }
);

export const getUserInstalledHabitPacks = createAsyncThunk(
  'habit_packs/get_user_installed_habit_packs',
  async (showInquiryModal: boolean, { dispatch }) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.USER_INSTALLED_HABIT_PACKS
      );
      if (showInquiryModal) {
        dispatch(updateIsInquiryModalShown(true));
      }
      return data ?? [];
    } catch (error) {
      dispatch(
        updateError({
          message: 'market.could_not_fetch_habit_packs',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updatePackMarketPlaceRequestStatus = createAsyncThunk(
  'habit_packs/update_marketplace_approval_status',
  async ({ habitPack, status }: UpdateApprovalStatusProps, { dispatch }) => {
    try {
      const modifiedHabitPack: HabitPackType = {
        ...habitPack,
        marketplace_request: status
      };
      dispatch(setCurrentViewedPack(modifiedHabitPack));
      dispatch(upsertHabitPack(modifiedHabitPack));
    } catch (error) {
      toast.error(i18n.t('market.could_not_update_habit_pack'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const removePackFromMarketplace = createAsyncThunk(
  'habit_packs/remove_pack_from_marketplace',
  async (habitPack: HabitPackType, { dispatch }) => {
    try {
      const modifiedHabitPack: HabitPackType = {
        ...habitPack,
        marketplace_approval_status: false
      };
      dispatch(setCurrentViewedPack(modifiedHabitPack));
      dispatch(upsertHabitPack(modifiedHabitPack));
      dispatch(getHabitPacks({ user_id: habitPack.user_id }));
    } catch (error) {
      toast.error(i18n.t('market.could_not_update_habit_pack'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const deleteHabitPack = createAsyncThunk(
  'habit_packs/delete_habit_pack',
  async (
    { packId, user_id }: { packId: string; user_id: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsHabitPacksDeleting(true));
      const response = await focusBearApi.delete(
        Endpoints.ACCESS_HABIT_PACK_BY_ID.replace('{pack_id}', packId)
      );
      dispatch(updateIsHabitPacksDeleting(false));
      if (response.status === 200) {
        toast.success(i18n.t('market.habit_pack_deleted'), {
          position: 'bottom-left',
          autoClose: 3000
        });
      }
      Promise.allSettled([
        dispatch(getHabitPacks({ user_id })),
        dispatch(getUserSettings())
      ]);
    } catch (error) {
      dispatch(updateIsHabitPacksDeleting(false));
      Sentry.captureException(JSON.stringify(error));
      toast.warning(i18n.t('market.could_not_delete_habit_pack'), {
        position: 'bottom-left',
        autoClose: 3000
      });
    }
  }
);

export const upsertHabitPack = createAsyncThunk(
  'habit_packs/upsert_habit_pack',
  async (habitPack: SettingsType, { dispatch }) => {
    try {
      dispatch(updateIsUserUpsertingPack(true));
      const { status, data } = await focusBearApi.put(
        Endpoints.HABIT_PACKS,
        habitPack
      );
      dispatch(updateIsUserUpsertingPack(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(getHabitPacks({ user_id: habitPack.id }));
        toast.success(i18n.t('market.habit_pack_saved_successfully'));
      } else {
        toast.warning(i18n.t('market.could_not_update_habit_pack'));
        Sentry.captureException(JSON.stringify({ status, data }));
      }
    } catch (error) {
      dispatch(updateIsUserUpsertingPack(false));
      toast.error(i18n.t('market.could_not_update_habit_pack'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const installHabitPack = createAsyncThunk(
  'habit_packs/install_habit_pack',
  async (packId: string, { dispatch }) => {
    try {
      dispatch(updateInstallingPack(true));
      const response = await focusBearApi.post(
        Endpoints.INSTALL_HABIT_PACK.replace('{pack_id}', packId)
      );
      if (response.status === HTTP_STATS_CODE.CREATED) {
        toast.success(i18n.t('market.habit_pack_install_success'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
        dispatch(changeIsSettingLoaded(false));
        dispatch(getUserInstalledHabitPacks(true));
      } else {
        toast.warning(i18n.t('market.habit_pack_install_fail'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
      }
      dispatch(updateInstallingPack(false));
    } catch (error) {
      toast.warning(i18n.t('market.habit_pack_install_fail'), {
        position: 'bottom-left',
        autoClose: TOAST_AUTO_CLOSE_TIMEOUT
      });
      dispatch(updateInstallingPack(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const installSharedHabitPack = createAsyncThunk(
  'habit_packs/install_habit_pack_as_default_settings',
  async (packId: string, { dispatch }) => {
    try {
      dispatch(updateInstallingPack(true));
      const { status } = await focusBearApi.get(
        Endpoints.INSTALL_HABIT_PACK_AS_DEFAULT_SETTINGS.replace(
          '{pack_id}',
          packId
        )
      );
      if (status === HTTP_STATS_CODE.SUCCESS) {
        toast.success(i18n.t('market.habit_pack_install_success'));
      } else {
        toast.warning(i18n.t('market.habit_pack_install_fail'));
      }
      dispatch(updateIsInquiryModalShown(true));
      dispatch(changeIsSettingLoaded(false));
      dispatch(updateInstallingPack(false));
    } catch (error) {
      dispatch(updateInstallingPack(false));
      toast.warning(i18n.t('market.habit_pack_install_fail'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const uninstallHabitPack = createAsyncThunk(
  'habit_packs/uninstall_habit_pack',
  async (packId: string, { dispatch }) => {
    try {
      dispatch(updateUninstallingPack(true));
      const response = await focusBearApi.delete(
        Endpoints.UNINSTALL_HABIT_PACK.replace('{pack_id}', packId)
      );
      if (response.status === 200) {
        toast.success(i18n.t('market.habit_pack_uninstall_success'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
        dispatch(changeIsSettingLoaded(false));
        dispatch(getUserInstalledHabitPacks(false));
      } else {
        toast.warning(i18n.t('market.habit_pack_install_fail'), {
          position: 'bottom-left',
          autoClose: TOAST_AUTO_CLOSE_TIMEOUT
        });
      }
      dispatch(updateUninstallingPack(false));
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      toast.warning(i18n.t('market.habit_pack_uninstall_fail'), {
        position: 'bottom-left',
        autoClose: TOAST_AUTO_CLOSE_TIMEOUT
      });
      dispatch(updateUninstallingPack(false));
    }
  }
);

export const getHabitPackById = createAsyncThunk(
  'habit_packs/get_habit_pack_by_id',
  async (
    { packId, isUserLoggedIn }: { packId: string; isUserLoggedIn: boolean },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsCurrentHabitPackFetching(true));
      const { data } = await focusBearApi.get(
        Endpoints.ACCESS_HABIT_PACK_BY_ID.replace('{pack_id}', packId)
      );
      if (isUserLoggedIn) {
        dispatch(updateIsUserInstalledPacksFetching(true));
        dispatch(getUserInstalledHabitPacks(false));
      }
      return data ?? null;
    } catch (error) {
      toast.error(t('market.could_not_fetch_habit_pack'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getSharedHabitPackById = createAsyncThunk(
  'habit_packs/get_shared_habit_pack_by_id',
  async (packId: string, { dispatch }) => {
    try {
      dispatch(updateIsSharedHabitPackFetching(true));
      const response = await fetch(
        `${BASE_URL}${Endpoints.ACCESS_HABIT_PACK_BY_ID.replace(
          '{pack_id}',
          packId
        )}`
      );
      const data = await response.json();
      if (data && data?.statusCode !== 404) {
        dispatch(updateSharedViewedPack(data));
      }
      dispatch(updateIsSharedHabitPackFetching(false));
    } catch (error) {
      dispatch(updateIsSharedHabitPackFetching(false));
      dispatch(
        updateError({
          message: 'market.could_not_fetch_habit_pack',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
