import { SubmitTaskPayload, ToDoPlayerTask } from 'interfaces';

export const areAllDurationsValid = (incomingTasks: SubmitTaskPayload[]) =>
  incomingTasks?.every((task) => hasTaskValidDuration(task));

export const hasTaskValidDuration = (task: SubmitTaskPayload) =>
  (task?.focusedDuration ?? 0) >= 0;

export function isValidToDoPlayerTaskArray(
  tasks: any[]
): tasks is ToDoPlayerTask[] {
  return tasks.every(
    (task) =>
      task && typeof task.id === 'string' && typeof task.name === 'string'
  );
}
