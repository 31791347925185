import Accordion from 'components/common/Accordion';
import AddHabitButton from 'components/setting-generator/tabs/AddHabitButton';
import COLOR from 'constants/color';
import { t } from 'i18next';
import { PropsWithChildren, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CustomRoutine from './CustomRoutine';
import CloseCircle from 'assets/icons/CloseCircle';
import colors from 'constants/color';
import { updateCustomRoutines } from 'store/reducer/setting/slice';
import { ROUTINE } from 'constants/general';

const CustomRoutinesWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full min-h-full h-fit max-h-full flex flex-col ~gap-6/10 items-center ~p-2/4 overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFourth'>
    {children}
  </div>
);

const CustomRoutines = () => {
  const dispatch = useAppDispatch();
  const customRoutines = useAppSelector(
    (state) => state.setting.currentSettings.custom_routines ?? []
  );

  const handleDelete = useCallback(
    (index: number) =>
      dispatch(
        updateCustomRoutines(customRoutines.filter((_, idx) => index !== idx))
      ),
    [customRoutines]
  );

  return (
    <CustomRoutinesWrapper>
      {customRoutines?.length
        ? customRoutines?.map((routine, idx) => (
            <div
              key={routine.id}
              className='w-full h-full flex flex-col items-center gap-10 relative'
            >
              <Accordion
                title={routine.name ? routine.name : t('custom_routine')}
                wrapperStyles='shadow'
                headerBackground='bg-sunRayFifth'
                headerStyles='~text-xs/base ~px-2/4 ~py-1/2'
                iconColor={COLOR.FOCUS_BEAR_DARK}
                contentBackground={COLOR.FOCUS_BEAR_LIGHT}
                open={true}
              >
                <CustomRoutine customRoutine={routine} position={idx} />
              </Accordion>
              <button
                onClick={() => handleDelete(idx)}
                className='absolute -top-2 -right-2 rounded-full bg-white'
              >
                <CloseCircle
                  styles='~w-4/5'
                  fill={colors.RED}
                  fillDarkMode={colors.RED}
                />
              </button>
            </div>
          ))
        : null}
      <AddHabitButton
        routine={!customRoutines.length ? ROUTINE.CUSTOM_ROUTINES : undefined}
        title={
          customRoutines.length
            ? t('add_custom_routine')
            : t('add_your_first_custom_routine')
        }
        additionalBtnStyles={customRoutines.length ? '~mt-6/10' : ''}
      />
    </CustomRoutinesWrapper>
  );
};

export default CustomRoutines;
