import { Mode } from 'constants/enum';
import { HABIT_QUESTIONS, TAB } from 'constants/general';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { tabBodyHeaderSelector } from 'store/reducer/setting/selectors';
import ShutdownTime from './ShutdownTime';
import TabBodyHeaderWidgets from './TabBodyHeaderWidgets';

const TabBodyHeaderWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit flex items-center relative py-1 sm:py-2'>
    {children}
  </div>
);

interface TabBodyHeaderProps {
  title: string;
  style?: React.CSSProperties;
}

const TabBodyHeader = ({ title, style }: TabBodyHeaderProps) => {
  const { t } = useTranslation();
  const { tabs, activeTabIndex, mode } = useAppSelector(tabBodyHeaderSelector);
  const shouldDisplayFilterWidget = [
    TAB.MORNING,
    TAB.MICRO_BREAKS,
    TAB.EVENING,
    TAB.ACTIVITY
  ].includes(tabs[activeTabIndex]);

  return (
    <TabBodyHeaderWrapper>
      {mode !== Mode.CUSTOM && (
        <div
          className='w-10/12 h-fit flex flex-col sm:flex-row ~gap-2/4 ~text-sm/xl'
          style={style}
        >
          {t(title)}
          {title === HABIT_QUESTIONS.EVENING_ROUTINE && <ShutdownTime />}
        </div>
      )}
      {shouldDisplayFilterWidget && <TabBodyHeaderWidgets tabTitle={title} />}
    </TabBodyHeaderWrapper>
  );
};

export default TabBodyHeader;
