import InputTime from 'components/common/inputs/InputTime';
import { TIME_FORMAT_24H } from 'constants/general';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionActivityCutoffTime } from 'store/reducer/modal/slice';

const ActivityCutoffTime = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: {
      currentSettings: { cutoff_time_for_non_high_priority_activities }
    }
  } = useAppSelector((state) => state);

  const isCutoffTimeValid = () =>
    moment(activity.cutoff_time_for_doing_activity, TIME_FORMAT_24H).isBefore(
      moment(cutoff_time_for_non_high_priority_activities, TIME_FORMAT_24H)
    );

  return (
    <InputTime
      selectedDate={activity?.cutoff_time_for_doing_activity ?? ''}
      setSelectedDate={(value: string) =>
        dispatch(updateMoreOptionActivityCutoffTime(value))
      }
      wrapperStyles='w-fit h-fit flex flex-col gap-0.5 font-medium'
      label={t('cutoff_time_for_doing_activity')}
      inputStyles='w-full text-xs sm:text-sm font-medium px-2 py-2 rounded-md border border-gray-100 outline-none shadow'
      tooltipMessage={t('cutoff_time_for_doing_activity_tooltip')}
      labelStyles='text-xs sm:text-sm'
      errorMessages={[t('the_time_should_be_after_the_global_cutoff_time')]}
      validateTime={isCutoffTimeValid}
    />
  );
};

export default ActivityCutoffTime;
