import { t } from 'i18next';
import { getRoutineTriggerOptions } from 'utils/settingsUtil';
import { CustomRoutineContext } from '..';
import { useCallback, useContext, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCustomRoutine } from 'store/reducer/setting/slice';
import { CUSTOM_ROUTINE_FEATURE } from 'constants/general';
import { CUSTOM_ROUTINE_TRIGGER } from 'constants/enum';
import Tooltip from 'components/common/Tooltip';
import classNames from 'classnames';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';
import COLOR from 'constants/color';

const RoutineTrigger = () => {
  const dispatch = useAppDispatch();
  const customRoutine = useContext(CustomRoutineContext);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );

  const triggerOptions = useMemo(
    () => getRoutineTriggerOptions(customRoutine?.name ?? 'custom_routine'),
    [customRoutine?.name]
  );

  const handleRoutineTrigger = useCallback(
    (value: CUSTOM_ROUTINE_TRIGGER) => {
      customRoutine &&
        dispatch(
          updateCustomRoutine({
            feature: CUSTOM_ROUTINE_FEATURE.TRIGGER,
            position: customRoutine?.position,
            value
          })
        );
    },
    [customRoutine?.position]
  );

  return (
    <div
      className={classNames(
        '~w-full/fit flex items-center justify-center ~gap-4/8 ~px-4/8 py-5 relative rounded-md shadow',
        {
          'bg-sunRayFifth': !shouldActivateDarkMode,
          'bg-gray-400': shouldActivateDarkMode
        }
      )}
    >
      <p
        className={classNames(
          'absolute -top-2 left-3 px-2 pb-0.5 rounded text-[0.7rem]',
          {
            'bg-sunRaySixth': !shouldActivateDarkMode,
            'bg-gray-600': shouldActivateDarkMode
          }
        )}
      >
        {t('trigger')}
      </p>
      {triggerOptions.map((option) => (
        <label
          key={option.value}
          htmlFor={option.value}
          className='min-w-max flex items-center ~gap-1.5/2 text-black'
        >
          <input
            className='~w-4/5 ~h-4/5 cursor-pointer'
            name={option.value}
            type='radio'
            checked={option.value === customRoutine?.trigger}
            onChange={() => {
              handleRoutineTrigger(option.value);
            }}
          />
          <span className='~text-xs/sm'>{option.label}</span>
          <Tooltip
            message={option.tooltip}
            iconStyles='~w-4/5 ~h-4/5 inline-flex ml-1'
            additionalWrapperStyles='w-fit'
            iconFillDarkMode={COLOR.GRAY_800}
          />
        </label>
      ))}
    </div>
  );
};

export default RoutineTrigger;
