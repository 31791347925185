import {
  CompletedActivityMetadata,
  CompletedActivityPayloadType,
  FocusSummarySearchProps,
  LevelRequirement,
  LogQuantityAnswer,
  RoutineActivitiesStatsSummary,
  ToDoPlayerTask,
  UserRank
} from 'interfaces';
import {
  AiToneOptions,
  DaysOfWeek,
  Entitlement,
  FEED_BACK_QUESTION,
  FONT_OPTION,
  LanguageCodes,
  TO_DO_STATUS
} from 'constants/enum';
import { v4 as uuid } from 'uuid';
import {
  ACTIVITY_TYPES,
  DASHBOARD,
  DEFAULT_FEEDBACK_CHOICES,
  DEFAULT_TAB_LAYOUT_TAB,
  DEFAULT_TODO_TASK_DURATION,
  EISENHOWER_QUADRANT,
  EISENHOWER_QUADRANT_OPTIONS,
  EMBEDDED_EXPLAINER_VIDEOS,
  EMPTY_STRING,
  FIRST_ACTIVITY_INDEX,
  FIRST_SETTING_TAB_INDEX,
  FOCUS_SUMMARY,
  FONTS,
  GENERAL_ACTIVITY_TYPES,
  GOALS_LEVEL,
  GOALS_LEVEL_TARGET,
  HABIT_CHOICE_TYPE,
  INITIAL_COMPETENCY_LEVEL,
  ITEM_INDEX_NOT_FOUND,
  LEVEL_KEYS,
  MAXIMUM_ALLOWED_MINUTES,
  MODAL_TYPES,
  NODE_ENV,
  NUMBERS,
  NUMBER_OF_GOALS_HEADERS,
  OPEN_STRIPE_CHECKOUT,
  PAGE,
  PLATFORMS,
  PRIORITY_OPTION,
  ROUTINE,
  ROUTINE_DESTINATION_POSITION,
  ROUTINE_SOURCE,
  SCREEN,
  SHORT_DAY_NAME,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PRICE,
  SUBSCRIPTION_PRICE_ID,
  TAB,
  TIME_UNIT_CONVERT_FACTOR
} from 'constants/general';
import moment from 'moment';
import { DateTime } from 'ts-luxon';
import i18n from 'services/i18n';
import Quotes from 'constants/quotes';
import { ROUTES } from 'constants/routes';
import {
  Achiever,
  Apprentice,
  Diligent,
  Disciplined,
  Explorer,
  Guardian,
  Mentor,
  Novice,
  Scholar,
  Strategist
} from 'assets/images';
import {
  ActivityType,
  ChoiceType,
  FocusModeTemplateType,
  HabitPackType,
  ToDoTask
} from 'interfaces/commonInterface';
import {
  AnswerChoice,
  FeedbackType,
  RoutinePlayerType,
  SettingsType
} from 'interfaces/settingInterface';
import {
  WeeklySummaryStatsTypes,
  WeeklyFocusBlockSummary,
  UserOnboardingStats
} from 'interfaces/userInterface';
import { SettingSlice } from 'interfaces/initialStatesTypes';
import { isCurrentPlayingActivityFromChoice } from './validation';
import { toast } from 'react-toastify';
import { ATTRIB } from 'constants/test';
import { FilterDataProps } from 'components/dashboard/to-do/tasks/tasks-toolbar';
import { DueDateFilters } from 'components/dashboard/to-do/tasks';
import { t } from 'i18next';
import parser from 'html-react-parser';
import { stripHtml } from 'string-strip-html';
import { DEFAULT_TO_DO_TASK_STATUS } from 'assets/data';
import { decode } from 'html-entities';
import DOMPurify from 'dompurify';
import 'moment-timezone';
import { IFRAME } from 'constants/tag';
import { isTeamOwnerOrAdmin } from './teamUtil';
import { isMobilePlatform } from './settingsUtil';

export const getYoutubeURLId = (url: string) => {
  const match = url.match(
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu(?:be)?\.com\/(?:v\/|embed\/|shorts\/|watch(?:\/|\?v=))|youtu\.be\/)((?:\w|-){11})(?:\S+)?$/
  );
  return match && String(match[1]).length === 11 ? match[1] : '';
};

const updateContent = (item: ActivityType, sequence_id: string) => {
  if (!('id' in item) || item.id === '') {
    item.id = uuid();
  }
  if (!('activity_sequence_id' in item) || item.activity_sequence_id === '') {
    item.activity_sequence_id = sequence_id;
  }
  item.choices &&
    item.choices.forEach((choice: ChoiceType) => {
      if (!('id' in choice) || choice.id === '') {
        choice.id = uuid();
      }
    });
};

export const updateDataWithID = (data: SettingsType) => {
  (data?.morning_activities ?? []).forEach((item: ActivityType) => {
    updateContent(item, uuid());
  });

  (data?.evening_activities ?? []).forEach((item: ActivityType) => {
    updateContent(item, uuid());
  });

  (data?.break_activities ?? []).forEach((item: ActivityType) => {
    updateContent(item, uuid());
  });

  return data;
};

export const oneDecimalPlacesIfNotWholeNumber = (value: number) =>
  value % 1 !== 0 ? Number(value.toFixed(1)) : value;

export const getPackIds = (installedPacks: HabitPackType[]) => {
  return installedPacks.map((pack) => pack.id);
};

export const packSearchHandler = (
  habitPacks: HabitPackType[],
  searchWord: string
) => {
  const packsToReturn: HabitPackType[] = habitPacks.filter((pack) => {
    if (
      pack.pack_name.toLowerCase().includes(searchWord.toLowerCase()) ||
      String(pack?.creator_name ?? EMPTY_STRING)
        .toLowerCase()
        .includes(searchWord.toLowerCase())
    ) {
      return pack;
    } else if (searchWord === '') {
      return habitPacks;
    } else {
      return false;
    }
  });
  return packsToReturn;
};

export const focusTemplateSearchHandler = (
  focusTemplates: FocusModeTemplateType[],
  searchWord: string
) => {
  const focusTemplatesToReturn: FocusModeTemplateType[] = focusTemplates.filter(
    (focusTemplate) => {
      if (
        focusTemplate.name?.toLowerCase().includes(searchWord.toLowerCase()) ||
        focusTemplate.author_name
          ?.toLowerCase()
          .includes(searchWord.toLowerCase())
      ) {
        return focusTemplate;
      } else if (searchWord === '') {
        return focusTemplates;
      } else {
        return false;
      }
    }
  );
  return focusTemplatesToReturn;
};

export const copyPackLink = (packId: string) => {
  window.navigator.clipboard.writeText(
    process.env.REACT_APP_SHARE_PACK_LINK + packId
  );
};

export const copyFocusTemplateLink = (templateId: string) => {
  window.navigator.clipboard.writeText(
    process.env.REACT_APP_SHARE_FOCUS_TEMPLATE_LINK + templateId
  );
};

export const updateWeeklySummaryStats = (
  weeklySummaryStats: WeeklySummaryStatsTypes[],
  payload:
    | { finish_time: string; start_time: string }[]
    | { finish_time: string; quantity_logged: string }[],
  isFocusModeSummary = true
) =>
  weeklySummaryStats.map((stat) => {
    let counter = 0;
    for (const index in payload) {
      if (
        moment(moment(payload[index].finish_time)).isSame(
          moment(stat.logged_date),
          'day'
        )
      ) {
        counter++;
      }
    }
    return isFocusModeSummary
      ? {
          ...stat,
          completed_focus_modes: counter
        }
      : {
          ...stat,
          completed_activities: counter
        };
  });

export const sortActivitiesAlphabetically = (activities: ActivityType[]) => {
  return activities?.sort(
    (precedingActivity, followingActivity) =>
      +!precedingActivity.name.toLowerCase() -
        +!followingActivity.name.toLowerCase() ||
      precedingActivity.name
        .toLowerCase()
        .localeCompare(followingActivity.name.toLowerCase())
  );
};

const groupAndMapGeneralActivities = (activities: ActivityType[]) => {
  const groupedMap = new Map();
  const generalActivities: ActivityType[] = [];
  activities.forEach((activity) => {
    if (GENERAL_ACTIVITY_TYPES.includes(activity.category ?? EMPTY_STRING)) {
      generalActivities.push(activity);
    } else {
      if (groupedMap.has(activity.category?.toLowerCase())) {
        groupedMap.get(activity.category?.toLowerCase()).push(activity);
      } else {
        groupedMap.set(activity.category?.toLowerCase(), [activity]);
      }
    }
  });
  return [groupedMap, generalActivities];
};

export const groupActivitiesIntoCategories = (activities: ActivityType[]) => {
  const indexedActivities = activities?.map((activity, index) => {
    return { ...activity, original_index: index };
  });
  const [groupedMap, generalActivities] =
    groupAndMapGeneralActivities(indexedActivities);

  // First category spot reserved for activities without category, even if none are included.
  // First array is the 'General' section in the activity library
  const alphabeticallySortedActivities = [
    generalActivities,
    ...Array.from(groupedMap.values())
  ].map((category) => sortActivitiesAlphabetically(category));
  return alphabeticallySortedActivities;
};

export const calculateSequenceDuration = (activities: ActivityType[]) => {
  return activities.reduce(
    (total, currentActivity) => total + currentActivity.duration_seconds,
    0
  );
};

export const determineRoutineNextStartTime = (
  timeZone: string,
  routineTime: string | undefined
) => {
  const currentTime = DateTime.local({ zone: timeZone });
  // there should always be a start_up or shut_down time to use, but their types
  // include undefined, so handling the undefined case here
  if (!routineTime) {
    return currentTime.toJSDate();
  }
  const startTimeForToday = DateTime.fromFormat(routineTime, 'hh:mm', {
    zone: timeZone
  });
  const isPastRoutineStartTimeForToday =
    currentTime.valueOf() > startTimeForToday.valueOf();
  // if routine's start time for current day has passed, use next day's start time
  return isPastRoutineStartTimeForToday
    ? startTimeForToday.plus({ days: 1 }).toJSDate()
    : startTimeForToday.toJSDate();
};

export const saveMarkerIndicatingUserOpenedStripe = () => {
  localStorage.setItem(OPEN_STRIPE_CHECKOUT, 'true');
};

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key));
};

export const updateLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const nextLevel = (current_level: number) =>
  current_level === NUMBERS.ZERO ? NUMBERS.ONE : ++current_level;

export const previousLevel = (current_level: number) =>
  current_level === NUMBERS.ZERO || current_level === NUMBERS.ONE
    ? NUMBERS.ONE
    : --current_level;

export const getTodayActivities = (activities: ActivityType[]) => {
  const today = moment().format(SHORT_DAY_NAME).toUpperCase();
  const key = today as DaysOfWeek;
  const todayActivities = activities.filter((activity) => {
    const wholeWeekActivity =
      activity.days_of_week?.includes(DaysOfWeek.ALL) ||
      !activity.days_of_week?.length; // the default value is empty array and it will be consider as a whole day activity
    const weekendActivity =
      activity.days_of_week?.includes(DaysOfWeek.WEEKEND) &&
      (today === DaysOfWeek.SAT || today === DaysOfWeek.SUN);
    const todayDayActivity = activity.days_of_week?.includes(DaysOfWeek[key]);
    return wholeWeekActivity || weekendActivity || todayDayActivity;
  });
  return todayActivities;
};

export const getActivitiesSortedByPriority = (activities: ActivityType[]) => {
  const highPriorityActivities = activities.filter(
    (activity) => activity?.priority === PRIORITY_OPTION.HIGH
  );
  const standardPriorityActivities = activities.filter(
    (activity) => activity?.priority !== PRIORITY_OPTION.HIGH
  );
  return [...highPriorityActivities, ...standardPriorityActivities];
};

export const handleRandomChoice = (activity: ActivityType) => {
  const choices = activity?.choices ?? [];
  if (choices.length) {
    const randomIndex = Math.floor(Math.random() * choices.length);
    return {
      ...activity,
      ...choices[randomIndex]
    };
  } else {
    return activity;
  }
};

export const handleCompetencyBasedChoice = (activity: ActivityType) => {
  const choices = activity?.choices ?? [];
  if (choices.length) {
    const level =
      activity?.current_competency_level ?? INITIAL_COMPETENCY_LEVEL;
    const choice = choices.find((choice) => choice.competency_level === level);
    return {
      ...activity,
      ...choice
    };
  } else {
    return activity;
  }
};

export const getRoutineActivitiesOfCurrentSettings = (
  type: string,
  currentSettings: SettingsType,
  customRoutinePosition?: number
) => {
  const TYPE_TO_ACTIVITY_MAPPING = {
    [ACTIVITY_TYPES.MORNING]: currentSettings.morning_activities ?? [],
    [ACTIVITY_TYPES.EVENING]: currentSettings.evening_activities ?? [],
    [ACTIVITY_TYPES.BREAKING]: currentSettings.break_activities ?? [],
    [ACTIVITY_TYPES.STANDALONE]: currentSettings.standalone_activities ?? [],
    [ACTIVITY_TYPES.CUSTOM_ROUTINES]:
      customRoutinePosition !== undefined
        ? currentSettings.custom_routines?.[customRoutinePosition]
            .standalone_activities ?? []
        : []
  };
  return TYPE_TO_ACTIVITY_MAPPING[type];
};

export const getYoutubeEmbeddedURL = (url: string) =>
  `https://www.youtube.com/embed/${getYoutubeURLId(
    url
  )}?rel=0&controls=0&modestbranding=1&disablekb=1&loop=1`;

export const getActivityIndexFromCurrentSettings = (
  type: string,
  currentSettings: SettingsType,
  activityId: string
) => {
  const routineActivities = getRoutineActivitiesOfCurrentSettings(
    type,
    currentSettings
  );
  const index = routineActivities?.findIndex((activity: ActivityType) => {
    const choices = activity?.choices ?? [];
    return choices.length
      ? choices.some((choice) => choice.id === activityId)
      : activity.id === activityId;
  });
  return index;
};

export const getActivitiesOfSettings = (
  type: string,
  settings: SettingSlice,
  customRoutinePosition?: number
) => {
  const activity = getRoutineActivitiesOfCurrentSettings(
    type,
    settings.currentSettings,
    customRoutinePosition
  );
  const TYPE_TO_ACTIVITY_MAPPING = {
    [ACTIVITY_TYPES.LIBRARY]: settings.activityLibrary ?? []
  };
  return activity ?? TYPE_TO_ACTIVITY_MAPPING[type];
};

export const getNextActivity = (
  routinePlayerActivities: RoutinePlayerType,
  currentPlayingActivity: ActivityType | null
) => {
  const index = routinePlayerActivities.activities.findIndex((activity) => {
    const choices = activity?.choices ?? [];
    if (choices.length) {
      const choiceIndex = choices.findIndex(
        (choice) => choice.id === currentPlayingActivity?.id
      );
      return choiceIndex !== ITEM_INDEX_NOT_FOUND;
    } else {
      return activity.id === currentPlayingActivity?.id;
    }
  });
  const isCurrentActivityNotLastInSequence =
    index !== NUMBERS.MINUS.ONE &&
    index + NUMBERS.ONE < routinePlayerActivities.activities.length;
  if (isCurrentActivityNotLastInSequence) {
    let nextActivity = routinePlayerActivities.activities[index + NUMBERS.ONE];
    if (nextActivity.choices?.length) {
      const isRandomChoiceType =
        nextActivity.choice_type === HABIT_CHOICE_TYPE.RANDOM;
      const isCompetencyChoiceType =
        nextActivity.choice_type === HABIT_CHOICE_TYPE.COMPETENCY_BASED;
      if (isRandomChoiceType) {
        nextActivity = handleRandomChoice(nextActivity);
      } else if (isCompetencyChoiceType) {
        nextActivity = handleCompetencyBasedChoice(nextActivity);
      }
    }
    return nextActivity;
  }
  // handle routine being completed
  return null;
};

export const getCompletedActivityPayload = (
  currentPlayingActivity: ActivityType,
  deviceId: string,
  routinePlayerActivities: RoutinePlayerType,
  log_quantity_answers: LogQuantityAnswer[],
  note: string | undefined,
  metadata?: CompletedActivityMetadata
) => {
  let completed_activity_payload: CompletedActivityPayloadType = {
    activity_id: currentPlayingActivity.id,
    duration_logged: currentPlayingActivity.duration_seconds,
    device_id: deviceId,
    activity_sequence_id: currentPlayingActivity.activity_sequence_id,
    start_time: moment()
      .subtract(currentPlayingActivity.duration_seconds, 'seconds')
      .toDate(),
    finish_time: moment().toDate(),
    metadata: metadata ?? {
      is_skipped: false,
      skipped_did_not_complete: false,
      skipped_did_complete: false
    },
    log_quantity_answers
  };

  const { parent_activity_id, choice_id } = isCurrentPlayingActivityFromChoice(
    routinePlayerActivities.activities,
    currentPlayingActivity
  );
  // create payload for activity with choices
  if (choice_id && parent_activity_id) {
    completed_activity_payload = {
      ...completed_activity_payload,
      activity_id: parent_activity_id,
      choice_id
    };
  } else if (parent_activity_id) {
    // create payload for an activity without a choices
    completed_activity_payload = {
      ...completed_activity_payload,
      activity_id: parent_activity_id
    };
  } else {
    completed_activity_payload = {
      ...completed_activity_payload,
      activity_id: currentPlayingActivity?.id ?? EMPTY_STRING
    };
  }

  if (note) {
    completed_activity_payload = {
      ...completed_activity_payload,
      note_logged: note
    };
  }

  return completed_activity_payload;
};

export const getCurrentPlatformFont = (
  platform: string,
  font?: FONT_OPTION
) => {
  if (font) {
    switch (font) {
      case FONT_OPTION.ARIAL:
        return FONTS.ARIAL;
      case FONT_OPTION.SEGEO_UI:
        return FONTS.SEGOE_UI;
      case FONT_OPTION.SAN_FRANCISCO:
        return FONTS.SAN_FRANCISCO;
      case FONT_OPTION.OPEN_DYSLEXIC:
        return FONTS.OPEN_DYSLEXIC;
      case FONT_OPTION.ROBOTO:
        return FONTS.ROBOTO;
      case FONT_OPTION.COMIC_SANS_MS:
        return FONTS.COMIC_SANS;
      case FONT_OPTION.HELVETICA:
        return FONTS.HELVETICA;
      case FONT_OPTION.INTEL_ONE_MONO:
        return FONTS.INTEL_ONE_MONO;
      case FONT_OPTION.CENTURY_GOTHIC:
        return FONTS.CENTURY_GOTHIC;
      case FONT_OPTION.VERDANA:
        return FONTS.VERDANA;
      case FONT_OPTION.TAHOMA:
        return FONTS.TAHOMA;
      case FONT_OPTION.TREBUCHET_MS:
        return FONTS.TREBUCHET_MS;
      case FONT_OPTION.POPPINS:
        return FONTS.POPPINS;
      default:
        return FONTS.SANS;
    }
  } else {
    // it supports older versions of the desktop app
    switch (platform) {
      case PLATFORMS.WIN:
        return FONTS.SEGOE_UI;
      case PLATFORMS.MAC:
        return FONTS.SAN_FRANCISCO;
      default:
        return FONTS.SANS;
    }
  }
};

export const getModalUrls = (
  modalType: string,
  activity: ActivityType,
  position: number,
  choices: ChoiceType[]
) => {
  let currentUrls: string[] = [];
  if (modalType === MODAL_TYPES.YOUTUBE_URLS) {
    currentUrls = activity.video_urls ?? [];
  } else if (modalType === MODAL_TYPES.CHOICE_YOUTUBE) {
    currentUrls = choices[position].video_urls ?? [];
  } else {
    currentUrls = activity?.allowed_urls ?? [];
  }
  return currentUrls;
};

export const getActivityDuration = (habit: ActivityType) =>
  habit.duration_seconds >= 60
    ? i18n.t('time_minutes', {
        count: Math.trunc(habit.duration_seconds / 60)
      })
    : i18n.t('time_seconds', { count: habit.duration_seconds });

export const increment = (value: number, step?: number) =>
  step ? value + step : ++value;

export const decrement = (value: number, step?: number) =>
  step ? value - step : --value;

export const getRandomQuote = () => {
  const position = Math.floor(Math.random() * Quotes.length);
  return Quotes[position];
};

export const getFocusSummaryFilteredData = (
  position: number,
  language: string,
  search: FocusSummarySearchProps,
  focusBlocks: WeeklyFocusBlockSummary[]
) => {
  switch (position) {
    case FOCUS_SUMMARY.COLUMN.DURATION:
      return focusBlocks.filter((focusBlock) =>
        moment
          .duration(
            focusBlock.focus_duration_seconds,
            FOCUS_SUMMARY.TIME_UNIT
              .SECOND as moment.unitOfTime.DurationConstructor
          )
          .locale(language)
          .humanize()
          ?.toLowerCase()
          .includes(search.word.toLowerCase())
      );
    case FOCUS_SUMMARY.COLUMN.START_TIME:
      return focusBlocks.filter((focusBlock) =>
        search.date
          ? moment(search.date).isSame(
              moment(focusBlock?.start_time),
              FOCUS_SUMMARY.TIME_UNIT.DAY as moment.unitOfTime.StartOf
            )
          : true
      );
    case FOCUS_SUMMARY.COLUMN.FINISH_TIME:
      return focusBlocks.filter((focusBlock) =>
        search.date
          ? moment(search.date).isSame(
              moment(focusBlock?.finish_time),
              FOCUS_SUMMARY.TIME_UNIT.DAY as moment.unitOfTime.StartOf
            )
          : true
      );
    case FOCUS_SUMMARY.COLUMN.INTENTION:
      return focusBlocks.filter((focusBlock) =>
        focusBlock?.intention?.toLowerCase().includes(search.word.toLowerCase())
      );
    case FOCUS_SUMMARY.COLUMN.ACHIEVEMENT:
      return focusBlocks.filter((focusBlock) =>
        focusBlock?.achievements
          ?.toLowerCase()
          .includes(search.word.toLowerCase())
      );
    case FOCUS_SUMMARY.COLUMN.DISTRACTION:
      return focusBlocks.filter((focusBlock) =>
        focusBlock?.distractions
          ?.toLowerCase()
          .includes(search.word.toLowerCase())
      );
    default:
      return focusBlocks.filter((focusBlock) =>
        focusBlock?.focus_mode?.name
          .toLowerCase()
          .includes(search.word.toLowerCase())
      );
  }
};

export const getFocusSummaryLabelsFormat = (
  format: moment.unitOfTime.StartOf
) => {
  if (format === FOCUS_SUMMARY.TIME_UNIT.MONTH) {
    return FOCUS_SUMMARY.TIME_FORMAT.MONTH_AND_YEAR;
  } else if (format === FOCUS_SUMMARY.TIME_UNIT.YEAR) {
    return FOCUS_SUMMARY.TIME_FORMAT.YEAR;
  } else {
    return FOCUS_SUMMARY.TIME_FORMAT.DAY_MONTH_AND_YEAR;
  }
};

export const getFocusSummaryGraphLabelsAndData = (
  weeklyFocusBlockSummary: WeeklyFocusBlockSummary[],
  format: moment.unitOfTime.StartOf,
  length: number
) => {
  const labels: string[] = [];
  const data: number[] = [];

  const formatType = getFocusSummaryLabelsFormat(format);
  weeklyFocusBlockSummary.forEach((focusBlock) => {
    !labels.includes(moment(focusBlock?.created_at).format(formatType)) &&
      labels.push(moment(focusBlock.created_at).format(formatType));
  });
  labels.forEach((month, index) => {
    let totalMinutes = NUMBERS.ZERO;
    weeklyFocusBlockSummary.forEach((focusBlock) => {
      if (moment(month).isSame(focusBlock?.created_at, format)) {
        totalMinutes += parseInt(
          focusBlock.focus_duration_seconds ?? NUMBERS.ZERO
        );
      }
    });
    data[index] = totalMinutes;
  });

  return {
    labels: labels.reverse().slice(NUMBERS.ZERO, length),
    data: data.reverse().slice(NUMBERS.ZERO, length)
  };
};

export const initWeeklySummaryStats = (days: number) =>
  Array.from({ length: days })
    .map((_, idx) => {
      return {
        logged_date: moment().subtract(idx, 'days').toDate(),
        completed_focus_modes: 0,
        completed_activities: 0,
        date: '',
        day_of_week: '',
        morning_percentage: 0,
        evening_percentage: 0,
        focus_modes: 0,
        morning_minutes: 0,
        morning_total_minutes: 0,
        evening_minutes: 0,
        evening_total_minutes: 0
      };
    })
    .reverse();

export const getRoutineStatsSummaryGraphLabelsAndData = (
  routineStatusSummary: RoutineActivitiesStatsSummary[],
  timeRange: { startDate: Date | null; endDate: Date | null }
) => {
  const updatedSummaries = routineStatusSummary
    ?.filter((summary) => {
      if (timeRange.startDate && timeRange.endDate) {
        return moment(summary.date).isBetween(
          timeRange.startDate,
          timeRange.endDate,
          'day',
          '[]'
        );
      } else {
        return moment().isSame(summary.date, 'day');
      }
    })
    ?.map((routineSummary) => ({
      labels: moment(routineSummary?.date).local(true).format('MMM DD, YYYY'),
      data: parseInt(routineSummary.summary ?? '0')
    }));

  return {
    labels: updatedSummaries?.map((summary) => summary.labels)?.reverse(),
    data: updatedSummaries?.map((summary) => summary.data)?.reverse()
  };
};

export const getToDoDefaultStatusLabel = (
  status: string,
  externalStatuses: { label: string; status_id: string }[] = []
) => {
  const external = externalStatuses.find(({ label }) => label === status);
  if (external) {
    return external.label;
  }
  switch (status) {
    case TO_DO_STATUS.NOT_STARTED:
      return i18n.t('to_do_procrastinate.not_started');
    case TO_DO_STATUS.IN_PROGRESS:
      return i18n.t('to_do_procrastinate.in_progress');
    case TO_DO_STATUS.COMPLETED:
      return i18n.t('to_do_procrastinate.completed');
    default:
      return status;
  }
};

export const getPageBoundaries = (
  currentPage: number,
  itemsPerPage: number
) => ({
  pageStartIndex:
    currentPage === PAGE.FIRST
      ? currentPage * itemsPerPage - itemsPerPage
      : increment(currentPage * itemsPerPage - itemsPerPage),
  pageEndIndex: decrement(currentPage * itemsPerPage)
});

export const isShortMotivationalSummary = (pathname: string) =>
  [ROUTES.WEBVIEW_MOBILE_MOTIVATIONAL_SUMMARY].includes(pathname);

export const getRandomAiTone = () => {
  const tones = Object.values(AiToneOptions);
  const randomArray = new Uint8Array(1); // returns a random a number between 0 - 255 of array length of 1
  const randomNumber = window.crypto.getRandomValues(randomArray);
  const randomIndex = randomNumber[0] % tones.length; // select the 1st
  return tones[randomIndex];
};

export const getMotivationalSummaryLangQueryParam = (lang: LanguageCodes) =>
  lang === LanguageCodes.ES ? 'Spanish' : 'English';

export const getLevelInfo = (level: number) => {
  const levelName = i18n.t(`level_name.${LEVEL_KEYS[level]}`);
  const pictures = [
    Novice,
    Apprentice,
    Explorer,
    Disciplined,
    Diligent,
    Strategist,
    Mentor,
    Scholar,
    Guardian,
    Achiever
  ];
  return [pictures[level], levelName];
};

export const getSubscriptionPlanInfo = (planId: string) => {
  if (planId === SUBSCRIPTION_PLAN.TEAM) {
    return {
      title: i18n.t('subscription_plan.team_subscription'),
      description: i18n.t('subscription_plan.team_description'),
      features: [
        i18n.t('subscription_plan.add_remove_users'),
        i18n.t('subscription_plan.productive_yet_private'),
        i18n.t('subscription_plan.project_management_integrations'),
        i18n.t('subscription_plan.team_leader_board')
      ],
      price: {
        amount: SUBSCRIPTION_PRICE.TEAM,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.TEAM
    };
  } else if (planId === SUBSCRIPTION_PLAN.ORGANIZATION) {
    return {
      title: 'Focus Bear organisation license for 5 years for 180 licenses',
      description:
        'Includes access to desktop and mobile apps for 180 licenses for 5 years',
      features: [],
      price: {
        amount: SUBSCRIPTION_PRICE.ORGANIZATION,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.ORGANIZATION
    };
  } else if (planId === SUBSCRIPTION_PLAN.PERSONAL_ANNUAL) {
    return {
      title: 'Personal annual subscription',
      description: '',
      features: [],
      price: {
        amount: SUBSCRIPTION_PRICE.PERSONAL_ANNUAL,
        postfix: i18n.t('subscription_plan.team_plan_postfix')
      },
      price_id: SUBSCRIPTION_PRICE_ID.PERSONAL_ANNUAL
    };
  }
  return {
    title: i18n.t('subscription_plan.personal_subscription'),
    description: i18n.t('subscription_plan.personal_description'),
    features: [
      i18n.t('subscription_plan.unlimited_ai_assisted_focus_modes'),
      i18n.t('subscription_plan.unlimited_pomodoro_modes'),
      i18n.t('subscription_plan.unlimited_meeting_modes'),
      i18n.t('subscription_plan.unlimited_automatic_goal_tracking')
    ],
    price: {
      amount: SUBSCRIPTION_PRICE.PERSONAL_MONTHLY,
      postfix: i18n.t('subscription_plan.personal_plan_postfix')
    },
    price_id: SUBSCRIPTION_PRICE_ID.PERSONAL_MONTHLY
  };
};

export const convertToSeconds = (minutes: number, seconds?: number) =>
  minutes * NUMBERS.SIXTY + (seconds ?? NUMBERS.ZERO);

export const convertToMinutes = (
  seconds: number,
  minutes?: number,
  isFractionAllowed?: boolean
) =>
  (isFractionAllowed
    ? (seconds ?? NUMBERS.ZERO) / NUMBERS.SIXTY
    : Math.floor((seconds ?? NUMBERS.ZERO) / NUMBERS.SIXTY)) +
  (minutes ?? NUMBERS.ZERO);

export const validateDuration = (seconds: number) => {
  seconds > convertToSeconds(MAXIMUM_ALLOWED_MINUTES) &&
    toast.error(i18n.t('validate_duration_min_max'));
};

export const getValidNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  } else {
    return isNaN(parseInt(value)) ? NUMBERS.ZERO : parseInt(value);
  }
};

export const getValidFocusSummaryColumnValue = (value?: string) =>
  value ? decode(value.replaceAll('+', ' ')) : '-';

export const getAccountDropDownMenus = (activeEntitlements: string[]) => {
  const accountMenuOptions = [
    {
      path: ROUTES.PROFILE,
      text: i18n.t('profile'),
      attribAction: ATTRIB.TEST.PROFILE
    },
    {
      path: ROUTES.MANAGE_SUBSCRIPTION,
      text: i18n.t('manage_subscription'),
      attribAction: ATTRIB.TEST.MANAGE_SUBSCRIPTION
    },
    {
      path: ROUTES.LOG_OUT
    }
  ];

  if (isTeamOwnerOrAdmin(activeEntitlements)) {
    accountMenuOptions.splice(1, 0, {
      path: ROUTES.TEAM,
      text: i18n.t('team'),
      attribAction: ATTRIB.TEST.TEAM
    });
  }

  return {
    accountMenuOptions,
    extraMenuOptions: [
      {
        path: ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES,
        text: i18n.t('packs.my_packs_and_focus_templates'),
        attribAction: ATTRIB.TEST.PACKS_AND_TEMPLATES
      },
      {
        path: ROUTES.MARKETPLACE,
        text: i18n.t('market.marketplace'),
        attribAction: ATTRIB.TEST.MARKETPLACE
      },
      {
        path: ROUTES.CHAT,
        text: i18n.t('chatbot.chat'),
        attribAction: ATTRIB.TEST.CHAT
      }
    ]
  };
};

export const adminSubscriptionStatusOptions = Object.values(Entitlement).map(
  (entitlement) => ({
    label: String(i18n.t(`entitlement.${entitlement}`)).replace(':', ''),
    value: entitlement
  })
);

export const getTodayDateWithOutTime = () =>
  new Date().toISOString().split('T')[0];

export const getFilteredAndOrderedTasks = (
  activeTab: number,
  tasks: ToDoTask[],
  { taskName, project, quadrant, focusMode, status }: FilterDataProps,
  currentPage: number,
  itemsPerPage: number,
  { dueToday, dueThisWeek, dueNextWeek }: DueDateFilters
) => {
  const { pageStartIndex, pageEndIndex } = getPageBoundaries(
    currentPage,
    itemsPerPage
  );
  if (activeTab === DEFAULT_TAB_LAYOUT_TAB) {
    return tasks?.slice(pageStartIndex, pageEndIndex)?.filter((task) => {
      const isNameMatched = task.title
        ? task.title?.toLowerCase()?.includes(taskName.toLowerCase())
        : true;
      const isFocusModeMatched = focusMode
        ? task.focus_type === focusMode.value
        : true;
      const isQuadrantMatched = quadrant
        ? parseInt(quadrant.value) === task.eisenhower_quadrant
        : true;
      const isProjectMatched = project
        ? task.tags.some((tag) => tag.text === project.label)
        : true;
      let isTaskStatusMatched = true;
      if (status) {
        const taskStatus = task.status ? task.status : TO_DO_STATUS.NOT_STARTED;
        isTaskStatusMatched = task.external_statuses?.length
          ? taskStatus === status.label
          : taskStatus === status.value;
      }
      const isTaskDueToday = dueToday
        ? moment(task.due_date).isSame(moment(), 'day')
        : true;
      const isTaskDueThisWeek = dueThisWeek
        ? moment(task.due_date).isSame(moment(), 'week')
        : true;
      const nextWeekStart = moment().add(1, 'weeks').startOf('isoWeek');
      const nextWeekEnd = moment().add(1, 'weeks').endOf('isoWeek');
      const isTaskDueNextWeek = dueNextWeek
        ? moment(task.due_date).isBetween(
            nextWeekStart,
            nextWeekEnd,
            'day',
            '[]'
          )
        : true;
      return (
        isNameMatched &&
        isFocusModeMatched &&
        isQuadrantMatched &&
        isProjectMatched &&
        isTaskStatusMatched &&
        isTaskDueToday &&
        isTaskDueThisWeek &&
        isTaskDueNextWeek &&
        task.status !== TO_DO_STATUS.COMPLETED
      );
    });
  }
  return tasks.filter((task) => task.status === TO_DO_STATUS.COMPLETED);
};

export const getNewHabitProps = (
  tabs: string[],
  activeTabIndex: number,
  {
    morning_activities,
    break_activities,
    evening_activities,
    standalone_activities,
    custom_routines
  }: SettingsType,
  customRoutine?: string,
  routines?: { title: string; value: boolean }[],
  isCustomRoutinesTab?: boolean,
  customRoutinePosition?: number
) => {
  if (isCustomRoutinesTab) {
    return [
      ACTIVITY_TYPES.CUSTOM_ROUTINES,
      customRoutinePosition !== undefined
        ? custom_routines?.slice()?.[customRoutinePosition]
            ?.activity_sequence_id ?? uuid()
        : uuid()
    ];
  } else {
    if (customRoutine) {
      const isMorningRoutineSelected = routines?.some(
        (routine) =>
          routine.value &&
          routine.title === t('routine_suggestion.morning_routine')
      );

      return activeTabIndex === FIRST_SETTING_TAB_INDEX &&
        (ROUTINE.MORNING === customRoutine || isMorningRoutineSelected)
        ? [
            ACTIVITY_TYPES.MORNING,
            morning_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
              ?.activity_sequence_id ?? uuid()
          ]
        : [
            ACTIVITY_TYPES.EVENING,
            evening_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
              ?.activity_sequence_id ?? uuid()
          ];
    } else {
      let newHabitProps = [
        ACTIVITY_TYPES.STANDALONE,
        standalone_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
          ?.activity_sequence_id ?? uuid()
      ];
      const isMobile = isMobilePlatform();
      if (tabs[activeTabIndex] === TAB.MORNING) {
        newHabitProps = [
          ACTIVITY_TYPES.MORNING,
          morning_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
            ?.activity_sequence_id ?? uuid()
        ];
      } else if (!isMobile && tabs[activeTabIndex] === TAB.MICRO_BREAKS) {
        newHabitProps = [
          ACTIVITY_TYPES.BREAKING,
          break_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
            ?.activity_sequence_id ?? uuid()
        ];
      } else if (
        isMobile ||
        [TAB.TIMING, TAB.EVENING].includes(tabs[activeTabIndex])
      ) {
        newHabitProps = [
          ACTIVITY_TYPES.EVENING,
          evening_activities?.slice()?.[FIRST_ACTIVITY_INDEX]
            ?.activity_sequence_id ?? uuid()
        ];
      }
      return newHabitProps;
    }
  }
};

export const getEisenhowerQuadrantInfo = (quadrant: string) => {
  switch (quadrant) {
    case EISENHOWER_QUADRANT.DO:
      return {
        category: i18n.t('to_do_procrastinate.urgent_and_important'),
        decision: t('to_do_procrastinate.do')
      };
    case EISENHOWER_QUADRANT.DECIDE:
      return {
        category: i18n.t('to_do_procrastinate.important_but_not_urgent'),
        decision: t('to_do_procrastinate.decide')
      };
    case EISENHOWER_QUADRANT.DELEGATE:
      return {
        category: i18n.t('to_do_procrastinate.not_important_and_urgent'),
        decision: t('to_do_procrastinate.delegate')
      };
    default:
      return {
        category: i18n.t('to_do_procrastinate.not_important_and_not_urgent'),
        decision: t('to_do_procrastinate.delete')
      };
  }
};

export const getToDoUpdateTask = (task?: ToDoTask) => {
  const quadrant = EISENHOWER_QUADRANT_OPTIONS.find(
    (option) => option.value === task?.eisenhower_quadrant?.toString()
  );
  const eisenhower_quadrant = quadrant
    ? { label: quadrant.label, value: quadrant.value }
    : { label: EMPTY_STRING, value: EMPTY_STRING };
  const tags = (task?.tags ?? []).map((tag) => ({
    label: tag.text,
    value: tag.id
  }));
  return {
    id: task?.id ?? uuid(),
    status: task?.status ?? TO_DO_STATUS.NOT_STARTED,
    title: task?.title ?? EMPTY_STRING,
    details: task?.details ?? EMPTY_STRING,
    due_date:
      moment(task?.due_date).format('YYYY-MM-DD') ??
      moment().format('YYYY-MM-DD'),
    eisenhower_quadrant,
    tags,
    subtasks: task?.subtasks ?? [],
    objective: task?.objective ?? ''
  };
};

export const createHtml = (value?: string) =>
  value ? parser(decodeURI(DOMPurify.sanitize(value))) : EMPTY_STRING;

export const removeHtmlTags = (value?: string) =>
  value ? stripHtml(value).result : EMPTY_STRING;

export const getSortedLeaders = (usersRanks: UserRank[], column?: string) => {
  switch (column) {
    case t('leader_board.morning_streak'):
      return usersRanks.sort(
        (a, b) =>
          parseInt(b.morning_percent_number_day_of_stats_completed) -
            parseInt(a.morning_percent_number_day_of_stats_completed) ||
          parseInt(b.morning_routines_streak) -
            parseInt(a.morning_routines_streak)
      );
    case t('leader_board.break_streak'):
      return usersRanks.sort(
        (a, b) =>
          parseInt(b.micro_breaks_streak) - parseInt(a.micro_breaks_streak)
      );
    case t('leader_board.evening_streak'):
      return usersRanks.sort(
        (a, b) =>
          parseInt(b.evening_percent_number_day_of_stats_completed) -
            parseInt(a.evening_percent_number_day_of_stats_completed) ||
          parseInt(b.evening_routines_streak) -
            parseInt(a.evening_routines_streak)
      );
    case t('leader_board.focus_mode'):
      return usersRanks.sort(
        (a, b) =>
          parseInt(b.focus_modes_streak) - parseInt(a.focus_modes_streak)
      );
    default:
      return usersRanks;
  }
};

export const getGoalsLabels = (
  level: number,
  onboardingStats?: UserOnboardingStats
) => {
  const headers = [
    t('stats_page.start'),
    ...Array.from({ length: NUMBER_OF_GOALS_HEADERS }).map((_, idx) =>
      t(`stats_page.level`, { level: increment(idx) })
    ),
    t('stats_page.end')
  ];

  let requirements: LevelRequirement[] = [];

  if (level) {
    requirements = [
      {
        title: t('stats_page.morning_routine_streak'),
        streak:
          onboardingStats?.morning_routine_completion_streak_days ??
          GOALS_LEVEL_TARGET[level][t('stats_page.morning_routine_streak')]
      },
      {
        title: t('stats_page.micro_break_streak'),
        streak:
          onboardingStats?.morning_routine_completion_streak_days ??
          GOALS_LEVEL_TARGET[level][t('stats_page.micro_break_streak')]
      },
      {
        title: t('stats_page.evening_routine_streak'),
        streak:
          onboardingStats?.evening_routine_completion_streak_days ??
          GOALS_LEVEL_TARGET[level][t('stats_page.evening_routine_streak')]
      },
      {
        title: t('stats_page.focus_mode_streak'),
        streak:
          onboardingStats?.focus_mode_completion_streak_days ??
          GOALS_LEVEL_TARGET[level][t('stats_page.focus_mode_streak')]
      }
    ];

    level === GOALS_LEVEL.level_3 &&
      requirements.push(
        ...[
          {
            title: t('stats_page.chat_with_focus_bear'),
            streak: Number(
              onboardingStats?.has_chatted_with_focus_bear ?? false
            )
          },
          {
            title: t('stats_page.setup_a_focus_mode'),
            streak: Number(onboardingStats?.has_edited_focus_mode ?? false),
            url: EMBEDDED_EXPLAINER_VIDEOS.SET_UP_A_FOCUS_MODE
          }
        ]
      );
  } else {
    requirements = [
      {
        title: t('stats_page.setup_always_blocked_urls'),
        streak: Number(onboardingStats?.has_edited_always_blocked_urls),
        url: EMBEDDED_EXPLAINER_VIDEOS.SETUP_ALWAYS_BLOCKED_URLS
      },
      {
        title: t('stats_page.install_mobile_app'),
        streak: Number(onboardingStats?.has_installed_mobile_app),
        url: EMBEDDED_EXPLAINER_VIDEOS.INSTALL_MOBILE_APPLICATION
      },
      {
        title: t('stats_page.edit_your_habits'),
        streak: Number(onboardingStats?.has_edited_settings),
        url: EMBEDDED_EXPLAINER_VIDEOS.EDIT_YOUR_HABITS
      }
    ];
  }

  return { headers, requirements };
};

export const getBooleanGoalsCurrentSteak = (
  label: string,
  onboardingStats: UserOnboardingStats
) => {
  if (label === t('stats_page.chat_with_focus_bear')) {
    return onboardingStats?.has_chatted_with_focus_bear ?? false;
  } else if (label === t('stats_page.setup_a_focus_mode')) {
    return onboardingStats?.has_edited_focus_mode ?? false;
  }
  return false;
};

export const formatToDoPlayerTaskTimeConstraints = (
  tasks: ToDoPlayerTask[]
) => {
  let previous = tasks?.length ? tasks[0] : null;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tasks?.map((task, index) => {
    const updatedTask = index
      ? {
          ...task,
          startTime: moment
            .tz(previous!.endTime, 'h:mm:ss a', userTimezone)
            .format('h:mm:ss a'),
          endTime: moment
            .tz(previous!.endTime, 'h:mm:ss a', userTimezone)
            .add(parseInt(String(task.duration)), 'seconds')
            .format('h:mm:ss a')
        }
      : {
          ...task,
          startTime: moment().tz(userTimezone).format('h:mm:ss a'),
          endTime: moment()
            .tz(userTimezone)
            .add(parseInt(String(task.duration)), 'seconds')
            .format('h:mm:ss a')
        };

    previous = updatedTask;
    return updatedTask;
  });
};

export const escapeSpecialEntities = (value: string) => {
  const specialAsciChars = [
    { symbol: '&amp;', value: '%26' },
    { symbol: '&lt;', value: '<' },
    { symbol: '&gt;', value: '>' }
  ];
  specialAsciChars.forEach((char) => {
    value = value.replaceAll(char.symbol, char.value);
  });
  return value;
};

export const getTodoTaskStatus = (task?: ToDoTask | null) => {
  return task?.external_statuses?.length
    ? task?.external_statuses.map((status) => ({
        label: status.label,
        value: uuid()
      }))
    : DEFAULT_TO_DO_TASK_STATUS;
};

export const getFeedbackChoices = (feedback: FeedbackType) => {
  let choices: AnswerChoice[] = [];
  if (feedback.type === FEED_BACK_QUESTION.BOOLEAN) {
    choices = (feedback?.options ?? [t('yes'), t('no')]).map((option) => ({
      checked: false,
      value: option
    }));
  } else if (feedback.type === FEED_BACK_QUESTION.NUMBER) {
    choices =
      feedback.options?.map((option) => ({ checked: false, value: option })) ??
      Array.from({ length: DEFAULT_FEEDBACK_CHOICES }).map((_, idx) => ({
        checked: false,
        value: String(idx)
      }));
  }
  return choices;
};

export const isDashboard = window.location.href.includes(DASHBOARD);

export const getTodoPlayerTaskDuration = (
  total_tasks: number,
  total_duration?: number,
  task_duration?: number
) => {
  const duration =
    task_duration && parseInt(task_duration.toString())
      ? parseInt(task_duration.toString())
      : DEFAULT_TODO_TASK_DURATION;
  if (total_tasks) {
    return total_duration ? increment(total_duration / total_tasks) : duration;
  }
  return duration;
};

export const isTaskExistsInToDoPlayer = (id: string, tasks: ToDoPlayerTask[]) =>
  tasks.some((playerTask) => playerTask.id === id);

export const getRoutineActivities = (activity_type: string) => {
  switch (activity_type) {
    case ACTIVITY_TYPES.MORNING:
      return ROUTINE_SOURCE.morning_activities;
    case ACTIVITY_TYPES.BREAKING:
      return ROUTINE_SOURCE.break_activities;
    default:
      return ROUTINE_SOURCE.evening_activities;
  }
};

export const getMoveActivityDestinationLabel = (position: string) => {
  switch (position) {
    case ROUTINE_DESTINATION_POSITION.START:
      return t('move_habit.start_of_the_routine');
    case ROUTINE_DESTINATION_POSITION.MIDDLE:
      return t('move_habit.middle_of_the_routine');
    default:
      return t('move_habit.end_of_the_routine');
  }
};

export const isProductionEnvironment =
  process.env.NODE_ENV === NODE_ENV.PRODUCTION;

export const getYouTubePlayerHeight = (width: number) => {
  if (width >= SCREEN.WIDTH.DOUBLE_EXTRA_LARGE) {
    return IFRAME.HEIGHT.DOUBLE_EXTRA_LARGE;
  } else if (width >= SCREEN.WIDTH.EXTRA_LARGE) {
    return IFRAME.HEIGHT.EXTRA_LARGE;
  } else if (width >= SCREEN.WIDTH.LARGE) {
    return IFRAME.HEIGHT.LARGE;
  } else if (width >= SCREEN.WIDTH.TABLET) {
    return IFRAME.HEIGHT.TABLET;
  } else if (width >= SCREEN.WIDTH.SMALL) {
    return IFRAME.HEIGHT.SMALL;
  } else {
    return IFRAME.HEIGHT.DEFAULT;
  }
};

export const getSurveyCategoryLabel = (value: string) => {
  switch (value) {
    case 'CHOICES_NUMBER':
      return t('admin.checkbox_with_text');
    case 'CHOICES_BOOLEAN':
      return t('admin.radio_button_with_text');
    case 'TEXT_AND_RATING':
      return t('admin.text_and_rating');
    default:
      return t('admin.text_only');
  }
};

export const getSubscriptionPlanTitle = (productIdentifier: string) => {
  if (isTeamSubscriptionPlan(productIdentifier)) {
    return t('focus_bear_team_subscription');
  } else {
    return productIdentifier.includes('trial') ? t('trial') : productIdentifier;
  }
};

export const getRoutineActivitiesFromSuggestions = (
  suggestions: ActivityType[],
  areSuggestionsSortedByDuration: boolean
) => {
  const { morning_activities, evening_activities } = suggestions.reduce(
    (
      activities: {
        morning_activities: ActivityType[];
        evening_activities: ActivityType[];
      },
      suggestion
    ) => {
      if (
        suggestion.activity_type === ROUTINE.MORNING &&
        !activities.morning_activities.find(
          (activity) => activity.name === suggestion.name
        )
      ) {
        activities.morning_activities.push({
          ...suggestion,
          duration_seconds: parseInt(suggestion.duration_seconds?.toString())
        });
      } else {
        if (
          !activities.evening_activities.find(
            (activity) => activity.name === suggestion.name
          )
        ) {
          activities.evening_activities.push({
            ...suggestion,
            duration_seconds: parseInt(suggestion.duration_seconds?.toString())
          });
        }
      }
      return activities;
    },
    { morning_activities: [], evening_activities: [] }
  );
  return !areSuggestionsSortedByDuration
    ? {
        morning_activities: morning_activities.sort(
          (activityA, activityB) =>
            activityA.duration_seconds - activityB.duration_seconds
        ),
        evening_activities: evening_activities.sort(
          (activityA, activityB) =>
            activityA.duration_seconds - activityB.duration_seconds
        )
      }
    : { morning_activities, evening_activities };
};

export const getRoutineValue = (
  routines: { title: string; value: boolean }[]
) =>
  routines
    .find((routine) => routine.value)
    ?.title?.toLowerCase()
    ?.includes(ROUTINE.MORNING)
    ? ROUTINE.MORNING
    : ROUTINE.EVENING;

export const filterOutCutoffTimeAndTutorial = (
  morning_activities: ActivityType[],
  break_activities: ActivityType[]
) => {
  return [
    morning_activities.map(({ cutoff_time_for_doing_activity, ...rest }) => ({
      ...rest
    })),
    break_activities.map(
      ({ cutoff_time_for_doing_activity, tutorial, ...rest }) => ({
        ...rest
      })
    )
  ];
};

export const getDurationFormat = (durationAsSecond: number) =>
  durationAsSecond >= TIME_UNIT_CONVERT_FACTOR.ONE_HOUR_AS_SECONDS
    ? 'h[h]:mm[m]:ss[s]'
    : 'mm[m]:ss[s]';

export const isUserInternalTest = (email: string): boolean =>
  /^internaltest(\+[\w-]+)?@focusbear\.io$/.test(email);

export const getItemsLocalStorage = (keys: string[]) => {
  return keys.map((key) => getLocalStorage(key));
};

export const isAlphaNumericInput = (value?: string) =>
  /^[a-zA-Z0-9\s]*$/.test(value ?? '');

export const isTeamSubscriptionPlan = (entitlement_product_id: string) =>
  [
    SUBSCRIPTION_PLAN.TEAM,
    SUBSCRIPTION_PLAN.RC_PROMO_TEAM_ADMIN_LIFE_TIME,
    SUBSCRIPTION_PLAN.RC_PROMO_TEAM_OWNER_LIFE_TIME,
    SUBSCRIPTION_PLAN.RC_PROMO_TEAM_MEMBER_MONTHLY,
    SUBSCRIPTION_PLAN.RC_PROMO_TEAM_MEMBER_LIFE_TIME,
    SUBSCRIPTION_PLAN.TEAM_SIZE_5
  ].includes(entitlement_product_id);

export const safeStringify = (data: any) =>
  typeof data === 'string' ? data : JSON.stringify(data);
