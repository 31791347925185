import RoutineDaysOfWeek from './RoutineDaysOfWeek';
import RoutineTimeBoundaries from './RoutineTimeBoundaries';
import RoutineTrigger from './RoutineTrigger';

const CustomRoutineOptions = () => (
  <div className='w-full flex items-center ~gap-4/16 flex-wrap'>
    <RoutineTrigger />
    <RoutineTimeBoundaries />
    <RoutineDaysOfWeek />
  </div>
);

export default CustomRoutineOptions;
